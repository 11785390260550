import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseCarrierService = () => {
  const secureAPI = useSecureAPI();
  
  const addWarehouseCarrier = async (data: any) => {
    const response = await secureAPI.post("WarehouseCarrier", data);
    return response.data;
  };
  const addWarehouseCarriersInBulk = async (data: any) => {
    const response = await secureAPI.post("WarehouseCarrier/bulk", data);
    return response.data;
  };
  const getWarehouseCarriersByWarehouseId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseCarrier/forwarehouse/${id}`);
    return response.data;
  }
  const getWarehouseCarrierById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseCarrier/${id}`);
    return response.data;
  }

  const updateWarehouseCarrier = async (data: any) => {
    const response = await secureAPI.put(`WarehouseCarrier`, data);
    return response.data;
  }
  const archiveWarehouseCarrier = async (id: number) => {
    const response = await secureAPI.put(`WarehouseCarrier/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseCarrier,
    addWarehouseCarriersInBulk,
    getWarehouseCarriersByWarehouseId,
    getWarehouseCarrierById,
    updateWarehouseCarrier,
    archiveWarehouseCarrier
  };
};

export default useWarehouseCarrierService;
