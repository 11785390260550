import useSecureAPI from "../hooks/usesecureapi";

const usePutawayRequestService = () => {
  const secureAPI = useSecureAPI();

  const getPutawayRequests = async () => {
    const response = await secureAPI.get("PutawayRequest");
    return response.data;
  };

  const getPutawayRequestById = async (id: number) => {
    const response = await secureAPI.get(`PutawayRequest/${id}`);
    return response.data;
  };

  const addPutawayRequest = async (data: any) => {
    const response = await secureAPI.post("PutawayRequest", data);
    return response.data;
  };

  const updatePutawayRequest = async (id: number, data: any) => {
    const response = await secureAPI.put(`PutawayRequest`, data);
    return response.data;
  };

  const deletePutawayRequest = async (id: number) => {
    const response = await secureAPI.delete(`PutawayRequest/${id}`);
    return response.data;
  };

  return {
    getPutawayRequests,
    getPutawayRequestById,
    addPutawayRequest,
    updatePutawayRequest,
    deletePutawayRequest,
  };
};

export default usePutawayRequestService;
