import useSecureAPI from "../hooks/usesecureapi";

const useStorageTypeService = () => {
  const secureAPI = useSecureAPI();

  const getStorageTypes = async () => {
    const response = await secureAPI.get("StorageType");
    return response.data;
  };

  const getStorageTypeById = async (id: number) => {
    const response = await secureAPI.get(`StorageType/${id}`);
    return response.data;
  };

  return {
    getStorageTypes,
    getStorageTypeById,
  };
};

export default useStorageTypeService;
