import { useEffect, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
  DATE_FORMAT
} from "../../../shared/globals";
import BoxDX from "../../layout/boxdx";
import ItemBox from "../../units_components/itembox";
import { useLocation, useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import DataGridDX from "../../layout/datagriddx";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";

const GoodsRecievingForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const requestData = state?.requestData;
  const { setError, setInfo } = useNotificationContext();
  const { createGRN } = useInboundRequestService();

  const ItemsColumns: GridColDef[] = [
    {
      field: "skuName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "skuCode",
      headerName: `${t("Code")}`,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "batchNumber",
      headerName: `${t("Batch Number")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "expiryDate",
      headerName: `${t("Expiry Date")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    { field: "quantity",
      headerName: `${t("Number of Units")}`,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
    }
  ];
  
  const defaultValues = {
    inboundRequestId: requestData.inboundRequestId,
    conditionTypeId: 1,
    numberOfCartons: 0,
    numberOfPallets: 0,
    numberOfLabour: 0,
    serialTagNumber: "",
    containerNumber: "",
    permitNumber: "",
    recievingDate: null,
  };

  const conditions = [
    { text: t("Good"), value: 1 },
    { text: t("Damaged"), value: 2 },
  ];

  const [data, setdata] = useState<any>(defaultValues);
  const [isFormEditable, setIsFormEditable] = useState(true);
  const [skus, setSKUs] = useState<any>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    navigate(-1);
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setdata({
      ...data,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setdata({
      ...data,
      [name]: moment(value).format(DATE_FORMAT),
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (data.recievingDate === null) newErrors['recievingDate'] = "Date is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = (status: any) => {
    if (validateForm()) {
      setIsLoading(true);
      createGRN(data)
        .then((res) => {
          setInfo(t("GRN created successfully"));
          navigate("/inboundrequests");
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false))
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {setSKUs(requestData.inboundRequestSkus)};
  
  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Goods Receiving Details")}
        onSaveClick={onSave}
        saveLabel={t("Confirm")}
        onYesClick={handleClose}
        isPrintable={false}
      >
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled={!isFormEditable}
            label={t("Receiving Date")}
            name="recievingDate"
            value={moment(data.recievingDate)}
            onChange={(value: any) => handleDateChange(value, "recievingDate")}
            error={errors["recievingDate"]}
            maxDate={moment()}
          />
        </GridDX>
        <GridDX item xs={0} md={8}>
        </GridDX>
        <GridDX item xs={12} md={3}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Goods Condition")}
            name="conditionTypeId"
            value={data.conditionTypeId}
            onChange={handleInputChange}
            items={conditions}
          />
        </GridDX>
        <GridDX item xs={12} md={3}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("No. of Cartons")}
            name="numberOfCartons"
            value={data.numberOfCartons}
            onChange={handleInputChange}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12} md={3}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("No. of Pallets")}
            name="numberOfPallets"
            value={data.numberOfPallets}
            onChange={handleInputChange}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12} md={3}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("No. of Labour")}
            name="numberOfLabour"
            value={data.numberOfLabour}
            onChange={handleInputChange}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Serial Tag No.")}
            name="serialTagNumber"
            value={data.serialTagNumber}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Container No.")}
            name="containerNumber"
            value={data.containerNumber}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Permit No.")}
            name="permitrNumber"
            value={data.permitNumber}
            onChange={handleInputChange}
          />
        </GridDX>
      </AddEditEmbeddedModal>
      <ItemBox>
        <DataGridDX
          getRowId={(row: any) => row.inboundRequestSkuId}
          rows={skus}
          columns={ItemsColumns}
          loading={isLoading}
          disableRowSelectionOnClick={true}
          checkboxSelection={false}
        />
      </ItemBox>
    </BoxDX>
  );
};

export default GoodsRecievingForm;
