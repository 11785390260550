import useSecureAPI from "../hooks/usesecureapi";

const useSKUService = () => {
  const secureAPI = useSecureAPI();

  const getSKUsForCustomer = async (id: number) => {
    const response = await secureAPI.get(`SKU/forcustomer/${id}`);
    return response.data;
  };

  const getSKUById = async (id: number) => {
    const response = await secureAPI.get(`SKU/${id}`);
    return response.data;
  };

  const addSKU = async (data: any) => {
    const response = await secureAPI.post("SKU", data);
    return response.data;
  };

  const bulkAddSKU = async (data: any) => {
    const response = await secureAPI.post("SKU/bulk", data);
    return response.data;
  };

  const updateSKU = async (data: any) => {
    const response = await secureAPI.put(`SKU`, data);
    return response.data;
  };

  const deleteSKU = async (id: number) => {
    const response = await secureAPI.put(`SKU/archive/${id}`);
    return response.data;
  };

  const deleteSKUPicture = async (id: number) => {
    const response = await secureAPI.delete(`SkuPicture/${id}`);
    return response.data;
  };

  return {
    getSKUsForCustomer,
    getSKUById,
    addSKU,
    bulkAddSKU,
    updateSKU,
    deleteSKU,
    deleteSKUPicture
  };
};

export default useSKUService;
