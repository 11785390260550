import { useEffect, useState } from "react";
import { t } from "i18next";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DATE_FORMAT } from "../../../shared/globals";
import BoxDX from "../../layout/boxdx";
import { useLocation, useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import DataGridDX from "../../layout/datagriddx";
import moment from "moment";
import ButtonDX from "../../controls/buttondx";
import TypographyDX from "../../layout/typographydx";
import OutboundItemQCForm from "./outbounditemqcform";
import GridSearchFilterTextbox from "../../business/gridsearchfiltertextbox";
import { Divider } from "@mui/material";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";

const OutboundQcForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const requestId = state?.requestId;
  const { getOutboundRequestById, updateOutboundRequest,markAsPacked } =
    useOutboundRequestService();
  const { setInfo, setError } = useNotificationContext();

  const ItemsColumns: GridColDef[] = [
    {
      field: "skuName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "skuCode",
      headerName: `${t("Code")}`,
      minWidth: 150,
    },
    {
      field: "expiryDate",
      headerName: `${t("Expiry Date")}`,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter(params) {
        return params.value ? moment(params.value).format(DATE_FORMAT) : "";
      },
    },
    {
      field: "recievedUnits",
      headerName: `${t("Received Units")}`,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      valueGetter(params) {
        return `${params.row.quantityReceived ?? 0}/${params.row.quantity}`;
      },
    },
    {
      field: "usableUnits",
      headerName: `${t("Usable Units")}`,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unusableUnits",
      headerName: `${t("Unusable Units")}`,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "quarantineUnits",
      headerName: `${t("Quarantine Units")}`,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: t("Actions"),
      field: "actions",
      align: "center",
      headerAlign: "center",
      renderCell(params) {
        return (
          <ButtonDX
            sx={{ m: 1 }}
            onClick={() => {
              console.log(params.row);
              setSelectedSKU(params.row);
              setOpenCountForm(true);
            }}
          >
            {t("Check")}
          </ButtonDX>
        );
      },
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const [requestData, setRequestData] = useState<any>(null);
  const [selectedSKU, setSelectedSKU] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [skus, setSKUs] = useState<any>([]);
  const [checked, setChecked] = useState<any>(0);
  const [isChanged, setIsChanged] = useState(false);
  const [openCountForm, setOpenCountForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    navigate("/outboundrequests");
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setSelectedSKU({
      ...selectedSKU,
      [name]: value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setSelectedSKU({
      ...selectedSKU,
      [name]: moment(value).format(DATE_FORMAT),
    });
  };

  const onSave = (status: any) => {
    // let data = {
    //   ...requestData,
    //   outboundRequestSkus: skus
    // };
    // if (status === 2) {
    //   data = {
    //     ...data,
    //     status: 9,
    //   };
    //   updateOutboundRequest(data)
    //     .then((res) => {
    //       setInfo(t("Moved to Put Away successfully"));
    //       navigate("/putawaypanel", {
    //         state: { requestId: data.outboundRequestId},
    //       });
    //     })
    //     .catch((err) => setError(err))
    //     .finally(() => setIsLoading(false));
    // }
    // else {
    //   updateRequest(data);
    // }
  };

  const updateRequest = async (data: any) => {
    // setIsLoading(true);
    // updateOutboundRequest(data)
    //   .then((res) => {
    //     setInfo(t("Request updated successfully"));
    //     setIsChanged(false);
    //   })
    //   .catch((err) => setError(err))
    //   .finally(() => setIsLoading(false));
  };

  const onClose = (confirm: boolean = false) => {
    let data = selectedSKU;
    const index = skus.findIndex(
      (sku: any) => sku.outboundRequestSkuId === selectedSKU.outboundRequestSkuId
    );

    if (confirm && !selectedSKU.checked) {
      setChecked(checked + 1);
      if (!skus[index].checked)
        data = {
          ...selectedSKU,
          checked: true,
        };
    }

    setSKUs([...skus.slice(0, index), { ...data }, ...skus.slice(index + 1)]);
    setRows([...skus.slice(0, index), { ...data }, ...skus.slice(index + 1)]);
    setSelectedSKU(null);
    setOpenCountForm(false);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(skus);
      return;
    }
    const newRows = skus.filter(
      (row: any) =>
        row.skuName.toLowerCase().includes(value.toLowerCase()) ||
        row.skuCode.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const getData = () => {
    setIsLoading(true);
    let check = 0;
    getOutboundRequestById(requestId)
      .then((res: any) => {
        const skuList = res.outboundRequestSkus.map((item: any) => {
          const usable = item.usableUnits;
          if (usable) check += 1;
          return {
            ...item,
            expiryDate: item.expiryDate ? moment(item.expiryDate).format(DATE_FORMAT) : null,
            checked: usable ? true : false,

            // dummy data
            skuName: `SKU Name ${item.skuId}`,
            skuCode: `SKU Code ${item.skuId}`,
          }
        });
        setRequestData(res);
        setChecked(check);
        setSKUs(skuList);
        setRows(skuList);
    })
    .catch((err: any) => setError(err))
    .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleMarkAsPacked = () => {
    if (checked.length === skus.length) {
      setIsLoading(true);
      markAsPacked(requestData.outboundRequestId)
        .then((res) => {
          setInfo(t("Items packed successfully!"));
          navigate(-1)
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    } else setError(t("Please pack all the items."));

  }

  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("QC Panel")}
        onSaveClick={onSave}
        showSaveAndSubmit={checked === skus.length}
        onSaveAndSubmit={handleMarkAsPacked}
        saveAndSubmitLabel={t("Move to Packed")}
        onYesClick={handleClose}
        isPrintable={false}
      >
        {openCountForm && (
          <OutboundItemQCForm
            data={selectedSKU}
            onChange={handleInputChange}
            onDateChange={handleDateChange}
            handleClose={onClose}
          />
        )}
        <GridDX container alignItems="center" columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={6} justifyContent="space-between">
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t(
                "Request No."
              )}`}</TypographyDX>
              <TypographyDX>{requestData?.outboundRequestId}</TypographyDX>
            </BoxDX>
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t(
                "Total SKUs"
              )}`}</TypographyDX>
              <TypographyDX>{skus.length}</TypographyDX>
            </BoxDX>
          </GridDX>
          <Divider sx={{ my: 1, width: "100%" }} />
          <GridDX item xs={12}>
            <TypographyDX fontWeight={"bold"}>{`${t(
              "QC InProgress"
            )} :`}</TypographyDX>
            <TypographyDX mx={2}>{`${checked}/${skus.length} ${t(
              "SKUs"
            )}`}</TypographyDX>
          </GridDX>
          <GridDX item xs={4}>
            <GridSearchFilterTextbox
              placeholder={t("Search")}
              sx={{
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px 0px #0000000F",
                border: "1px solid #D0D5DD",
              }}
              setGridFilterCriteria={setGridFilterCriteria}
            />
          </GridDX>
          <GridDX item xs={12}>
            <DataGridDX
              getRowId={(row: any) => row.outboundRequestSkuId}
              rows={rows}
              columns={ItemsColumns}
              loading={isLoading}
              disableRowSelectionOnClick={true}
              checkboxSelection={false}
            />
          </GridDX>
        </GridDX>
      </AddEditEmbeddedModal>
    </BoxDX>
  );
};

export default OutboundQcForm;
