import "./App.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuthContext } from "./context/authcontext";
import { NotificationContextProvider } from "./context/notificationcontext";
import { ConfigProvider, useConfigContext } from "./context/configcontext";

import LoadingOverlay from "./components/layout/loadingoverlay";
import ProtectedRoute from "./components/route/protectedroute";

import useRefreshToken from "./shared/hooks/userefreshtoken";

import AccountTemplate from "./templates/accounttemplate";

import LoginPage from "./pages/account/unitslogin";
import GetEmail from "./pages/account/forgetPassword/getEmail";
import ResetPassword from "./pages/account/forgetPassword/resetPassword";

import RequestQuoteForm from "./pages/requestaquote";
import Dashboard from "./pages/dashboard";
import Leads from "./pages/leads";
import Quotations from "./pages/quotations";
import OutboundRequests from "./pages/outboundrequests/outboundrequests";
import InboundRequests from "./pages/inboundrequests/inboundrequests";
import PutawayRequests from "./pages/putawayrequests";
import UnitsListPageTemplate from "./templates/units_listpagetemplate";
import UnitsMainTemplate from "./templates/units_maintemplate";
import { useTranslation } from "react-i18next";
import Users from "./pages/settings/users";
import WareHouse from "./pages/settings/warehouse";
import Packages from "./pages/settings/packages";
import SKU from "./pages/sku/sku";
import CustomerAddress from "./pages/customeraddress";
import DetailsPageTemplate from "./templates/detailspagetemplate";
import Reports from "./pages/reports";
import AcceptQuotation from "./pages/acceptquotation";
import WarehouseDetails from "./components/pages/warehouse/warehousedetails";
import SKUReport from "./components/pages/reports/customer_reports/skureport";
import Customers from "./pages/settings/customers";
import LeadForm from "./components/units_forms/leads/leadform";
import CustomerDetails from "./components/pages/customers/customerdetails";
import QuotationForm from "./components/units_forms/quotations/quotationform";
import InboundRequestForm from "./components/units_forms/inboundrequests/inboundrequestform";
import OutboundRequestForm from "./components/units_forms/outboundrequests/outboundrequestform";
import PackageForm from "./components/units_forms/packageform";
import UserDetails from "./components/units_forms/userdetails";
import InboundRequestProcessForm from "./components/units_forms/inboundrequests/inboundrequestprocessform";
import PackingMaterial from "./pages/settings/packingmaterials";
import Brands from "./pages/settings/brands";
import SKUCategory from "./pages/settings/skucategory";
import SKUCategoryForm from "./components/units_forms/sku/skucategoryform";
import Suppliers from "./pages/settings/suppliers";
import SkuKit from "./pages/sku/skukit";
import WarehouseAreaDetails from "./components/pages/warehouse/tab_content/warehouseareadetails";
import WarehouseRackDetails from "./components/pages/warehouse/tab_content/warehouserackdetails";
import WarehouseRackLocationDetails from "./components/pages/warehouse/tab_content/warehouseracklocationdetails";
import WarehouseTimeslots from "./pages/settings/timeslots";
import WarehouseTimeslotForm from "./components/units_forms/timeslotform";
import OrderBins from "./pages/settings/orderbins";
import Carriers from "./pages/settings/carriers";
import GoodsRecievingForm from "./components/units_forms/inboundrequests/goodsrecievingform";
import PutAwayForm from "./components/units_forms/inboundrequests/putawayform";
import CountingForm from "./components/units_forms/inboundrequests/countingform";
import QCForm from "./components/units_forms/inboundrequests/qcform";
import WarehouseOrderBinForm from "./components/units_forms/orderbins/orderbinform";
import BrandForm from "./components/units_forms/brands/brandform";
import SupplierForm from "./components/units_forms/suppliers/supplierform";
import PackingMaterialForm from "./components/units_forms/packingmaterials/packingmaterialform";
import WarehouseCarrierForm from "./components/units_forms/carriers/carrierform";
import PickingForm from "./components/units_forms/outboundrequests/pickingform";
import OutboundQcForm from "./components/units_forms/outboundrequests/outboundqcform";
import PackingForm from "./components/units_forms/outboundrequests/packingform";

function App() {
  return (
    <ConfigProvider>
      <NotificationContextProvider>
        <AuthProvider>
          <Router>
            <ApplicationRoutes />
          </Router>
        </AuthProvider>
      </NotificationContextProvider>
    </ConfigProvider>
  );
}

const ApplicationRoutes = () => {
  const { t, i18n } = useTranslation();
  const { authInitialized } = useAuthContext();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { isLoggedIn, fullToken, userData, signOut } = useAuthContext();
  const refresh = useRefreshToken();
  const location = useLocation();

  const refreshToken = () => {
    if (userData.rememberMe) {
      refresh()
        .then(() => {
          console.log("refreshed");
        })
        .catch(() => {
          signOut();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      signOut();
    }
  };

  useEffect(() => {
    if (authInitialized && !isLoggedIn) {
      setLoading(false);
    } else if (authInitialized && isLoggedIn && fullToken) {
      const currentDateTime = new Date().getTime();
      const expiryDateTime = new Date(fullToken.expiryDate).getTime();
      if (expiryDateTime < currentDateTime) {
        refreshToken();
      } else if (
        location.pathname === "/" ||
        location.pathname === "/forget-password"
      ) {
        setLoading(false);
        //to redirect to dashboard if user is logged in and tries to access login page
        navigate("/dashboard");
      } else {
        setLoading(false);
      }
    }
  }, [location.pathname, isLoggedIn, authInitialized]);

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    // Check last selected language from localStorage
    const lastLanguage = localStorage.getItem("lastLanguage");
    if (lastLanguage) {
      // Set the language in i18n
      i18n.changeLanguage(lastLanguage);
    }
    setInitialLoading(false);
  }, []);

  if (initialLoading || loading) return <LoadingOverlay />;
  else
    return (
      <Routes>
        <Route element={<AccountTemplate />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forget-password" element={<GetEmail />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/requestaquote" element={<RequestQuoteForm />} />
          <Route path="/acceptaquote/:id" element={<AcceptQuotation />} />
        </Route>
        <Route
          element={
            <ProtectedRoute>
              <UnitsMainTemplate />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/skureport" element={<SKUReport />} />
          <Route element={<UnitsListPageTemplate />}>
            <Route path="/brands" element={<Brands />} />
            <Route path="/leads" element={<Leads />} />
            <Route path="/quotations" element={<Quotations />} />
            <Route path="/inboundrequests" element={<InboundRequests />} />
            <Route path="/outboundrequests" element={<OutboundRequests />} />
            <Route path="/putawayrequests" element={<PutawayRequests />} />
            <Route path="/users" element={<Users />} />
            <Route path="/warehouses" element={<WareHouse />} />
            <Route path="/warehousecarriers" element={<Carriers />} />
            <Route path="/warehouseorderbins" element={<OrderBins />} />
            <Route
              path="/warehousetimeslots"
              element={<WarehouseTimeslots />}
            />
            <Route path="/packages" element={<Packages />} />
            <Route path="/packingmaterials" element={<PackingMaterial />} />
            <Route path="/sku" element={<SKU />} />
            <Route path="/skukit" element={<SkuKit />} />
            <Route path="/skucategory" element={<SKUCategory />} />
            <Route path="/supplier" element={<Suppliers />} />
            <Route path="/customeraddress" element={<CustomerAddress />} />
            <Route path="/customers" element={<Customers />} />
          </Route>
          <Route element={<DetailsPageTemplate />}>
            <Route path="/warehousedetails" element={<WarehouseDetails />} />
            <Route
              path="/warehouseareadetails"
              element={<WarehouseAreaDetails />}
            />
            <Route
              path="/warehouserackdetails"
              element={<WarehouseRackDetails />}
            />
            <Route
              path="/warehouseracklocationdetails"
              element={<WarehouseRackLocationDetails />}
            />
            <Route path="/customerdetails" element={<CustomerDetails />} />
          </Route>
          <Route
            path="/packingmaterialdetails"
            element={<PackingMaterialForm />}
          />
          <Route
            path="/warehousecarrierdetails"
            element={<WarehouseCarrierForm />}
          />
          <Route
            path="/warehouseorderbindetails"
            element={<WarehouseOrderBinForm />}
          />
          <Route
            path="/warehousetimeslotdetails"
            element={<WarehouseTimeslotForm />}
          />
          <Route path="/branddetails" element={<BrandForm />} />
          <Route path="/leaddetails" element={<LeadForm />} />
          <Route path="/skucategorydetails" element={<SKUCategoryForm />} />
          <Route path="/supplierdetails" element={<SupplierForm />} />
          <Route path="/quotationdetails" element={<QuotationForm />} />
          <Route path="/inbounddetails" element={<InboundRequestForm />} />
          <Route
            path="/goodsrecievingdetails"
            element={<GoodsRecievingForm />}
          />
          <Route path="/countingpanel" element={<CountingForm />} />
          <Route path="/qcpanel" element={<QCForm />} />
          <Route path="/putawaypanel" element={<PutAwayForm />} />
          <Route path="/outbounddetails" element={<OutboundRequestForm />} />
          <Route path="/packagedetails" element={<PackageForm />} />
          <Route path="/userdetails" element={<UserDetails />} />
          <Route
            path="/processinboundrequest"
            element={<InboundRequestProcessForm />}
          />
          <Route path="/pickingpanel" element={<PickingForm />} />
          <Route path="/packingpanel" element={<PackingForm />} />
          <Route path="/outboundqcpanel" element={<OutboundQcForm />} />
          {/* to redirect user to dashboard if he tries to access invalid url */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Route>
      </Routes>
    );
};

export default App;
