import AddEditModalDX from "../../business/addeditmodaldx";
import { t } from "i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import TypographyDX from "../../layout/typographydx";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";
import { useNotificationContext } from "../../../context/notificationcontext";

const ItemCountForm = (props: any) => {
  const {
    data,
    onChange,
    onDateChange,
    handleClose,
  } = props;
  const { setError } = useNotificationContext();


  const onSave = () => {
    if (parseInt(data.quantityReceived ?? 0) + parseInt(data.missingUnits ?? 0) > data.quantity) 
      setError(t("Quantity entered is greater than total"));
    else if (!data.quantityReceived) setError(t("Enter the quantity received"));
    else handleClose(true);
  };

  return (
    <AddEditModalDX
      open={true}
      title={t("Count Item")}
      handleClose={handleClose}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      <GridDX container rowSpacing={2} columnSpacing={1}>
        <GridDX item xs={12}>
          <TypographyDX fontWeight={"bold"}>
            {`${t("Selected SKU")}: `}
          </TypographyDX>
          <TypographyDX mx={1}>
            {data.skuName}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} mb={1}>
          <TypographyDX fontWeight={"bold"}>
            {`${t("Code")}: `}
          </TypographyDX>
          <TypographyDX mx={1}>
            {data.skuCode}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Received Units")}
            name="quantityReceived"
            value={data.quantityReceived}
            onChange={onChange}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            disabled
            label={t("Missing Units")}
            name="missingUnits"
            value={data.missingUnits}
            onChange={onChange}
            type="number"
            // Disable spin buttons for number input
            className="noSpinButtons"
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Batch Number")}
            name="batchNumber"
            value={data.batchNumber}
            onChange={onChange}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <UnitsDatePicker
            label={t("Expiry Date")}
            name="expiryDate"
            value={moment(data.expiryDate)}
            onChange={(value: any) => onDateChange(value, "expiryDate")}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default ItemCountForm;