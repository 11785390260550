import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseRackService = () => {
  const secureAPI = useSecureAPI();
  
  const addWarehouseRack = async (data: any) => {
    const response = await secureAPI.post("WarehouseRack", data);
    return response.data;
  };
  const addWarehouseRacksInBulk = async (data: any) => {
    const response = await secureAPI.post("WarehouseRack/bulk", data);
    return response.data;
  };
  const getWarehouseRacksByWarehouseId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseRack/forwarehouse/${id}`);
    return response.data;
  }
  const getWarehouseRackById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseRack/${id}`);
    return response.data;
  }

  const updateWarehouseRack = async (data: any) => {
    const response = await secureAPI.put(`WarehouseRack`, data);
    return response.data;
  }
  const archiveWarehouseRack = async (id: number) => {
    const response = await secureAPI.put(`WarehouseRack/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseRack,
    addWarehouseRacksInBulk,
    getWarehouseRacksByWarehouseId,
    getWarehouseRackById,
    updateWarehouseRack,
    archiveWarehouseRack
  };
};

export default useWarehouseRackService;
