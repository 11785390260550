import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../../business/listpagedx";
import ListPageTemplate from "../../../templates/listpagetemplate";
import GridDX from "../../layout/griddx";
import ButtonDX from "../../controls/buttondx";
import TypographyDX from "../../layout/typographydx";
import { AddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNotificationContext } from "../../../context/notificationcontext";
import { getTotalAmount } from "../../../shared/globals";
import InboundRequestItemForm from "../../units_forms/inboundrequests/inboundrequestitemform";
import TypeTranslator from "../../../shared/typetranslator";
import { useAuthContext } from "../../../context/authcontext";
import BoxDX from "../../layout/boxdx";
import placeholderskuimage from "../../../assets/placeholderskuimage5050.png";
const InboundRequestItems = (props: any) => {
  const { userData } = useAuthContext();
  const {
    requestData,
    setRequestData,
    getData,
    setIsChanged,
    skus,
    skuDropdownData,
    isFormEditable,
    isProcessInitiated,
    getSkuDropDownData,
  } = props;
  const [requestItemId, setRequestItemId] = useState(null);
  const { t, i18n } = useTranslation();
  const [rows, setRows] = useState<any>(requestData.inboundRequestSkus);
  const [items, setItems] = useState<any>(requestData.inboundRequestSkus);

  const [isLoading, setIsLoading] = useState(false);
  const { setInfo } = useNotificationContext();
  const { getPackagingTypeValue } = TypeTranslator();

  const columns: GridColDef[] = [
    {
      field: "skuPictureURL",
      headerName: t("Image"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <BoxDX sx={{ p: 1 }}>
            <img
              src={
                skus.find((s: any) => s.skuId === params.row.skuId)
                  ?.skuPictureURL || placeholderskuimage
              }
              alt={params.row.skuId}
              style={{ width: 50, height: 50 }}
            />
          </BoxDX>
        );
      },
    },
    {
      field: "skuId",
      headerName: t("SKU"),
      flex: 1,
      valueGetter: (params: any) => {
        const sku = skus.find((s: any) => s.skuId === params.row.skuId);
        return sku ? sku.name : "";
      },
    },
    {
      field: "quantity",
      headerName: t("SKU Quantity"),
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "packagingTypeId",
      headerName: t("Packaging Type"),
      flex: 1,
      valueGetter: (params: any) => {
        return getPackagingTypeValue(params.row.packagingTypeId);
      },
    },
    //isPerishable
    {
      field: "isPerishable",
      headerName: t("Perishable"),
      flex: 1,
      valueGetter: (params: any) => {
        const sku = skus.find((s: any) => s.skuId === params.row.skuId);
        return sku?.isPerishable ? t("Yes") : t("No");
      },
    },
  ];

  const onDelete = (id: number) => {
    setRequestData((prev: any) => {
      const deletedItem = prev.inboundRequestSkus.find(
        (item: any) => item.item_id === id
      );
      const updatedItems = prev.inboundRequestSkus.filter(
        (item: any) => item.item_id !== id
      );
      const updatedData = {
        ...prev,
        inboundRequestSkus: [
          ...updatedItems,
          { ...deletedItem, isDeleted: true },
        ],
      };
      return updatedData;
    });
    setIsChanged(true);
    setInfo("Item deleted successfully");
  };

  const [showForm, setShowForm] = useState(false);
  const onEdit = (data: any) => {
    setRequestItemId(data.item_id);
    setShowForm(true);
  };

  const toCreate = () => {
    setShowForm(true);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowForm(false);
    setRequestItemId(null);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(items);
      return;
    }
    const newRows = items.filter(
      ({ quantity, skuId, packagingTypeId }: any) => {
        return (
          quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
          getPackagingTypeValue(packagingTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          skus
            .find((s: any) => s.skuId === skuId)
            .name.toLowerCase()
            .includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    const filteredItems = requestData?.inboundRequestSkus?.filter(
      (item: any) => !item.isDeleted
    );
    setRows(filteredItems);
    setItems(filteredItems);
  }, [requestData.inboundRequestSkus]);
  return (
    <>
      {showForm && (
        <InboundRequestItemForm
          requestData={requestData}
          requestItemId={requestItemId}
          setRequestData={setRequestData}
          handleClose={onClose}
          setIsChanged={setIsChanged}
          skus={skus}
          skuDropdownData={skuDropdownData}
          getSkuDropDownData={getSkuDropDownData}
        />
      )}
      <GridDX container xs={12}>
        <GridDX item xs={12} justifyContent="space-between">
          <TypographyDX variant="h5" fontWeight="bold">
            {t("Items")}
          </TypographyDX>
          {(isFormEditable ||
            (userData.userType == "S" &&
              requestData.status == 3 &&
              isProcessInitiated)) && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: "#fff", wordSpacing: 4 }}
              onClick={toCreate}
            >
              <AddOutlined /> {t("Add Item")}
            </Button>
          )}
        </GridDX>
        <GridDX item xs={12} sx={{ mb: 2 }}>
          <ListPageDX
            listTitle={t("Item")}
            name={t("Item")}
            rows={rows}
            columns={columns}
            getRowId={(row: any) => row.item_id}
            isLoading={isLoading}
            // toCreate={toCreate}
            editLabel={t("Edit")}
            // enabled (for admin only) if the request is approved or in draft
            onEdit={
              (isFormEditable ||
                (userData.userType == "S" &&
                  requestData.status == 3 &&
                  isProcessInitiated) ||
                requestData.status == 5) &&
              onEdit
            }
            onDelete={
              (isFormEditable ||
                (userData.userType == "S" &&
                  requestData.status == 3 &&
                  isProcessInitiated)) &&
              onDelete
            }
            exportToPDF={false}
            exportToCSV={false}
            showDatePicker={false}
            showSelect={false}
            searchAndFilter={true}
            exportDropdown={false}
            setGridFilterCriteria={setGridFilterCriteria}
          />
        </GridDX>
      </GridDX>
    </>
  );
};

export default InboundRequestItems;
