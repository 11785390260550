import { Chip, Input, Typography } from "@mui/material";
import TagsInput from "react-tagsinput";
import "../../styles/tagstyle.css";
import { useTranslation } from "react-i18next";
import ChipDX from "./chipdx";

const TagInputDX = (props: any) => {
  const { t, i18n } = useTranslation();

  const handleTagsChange = (tags: any) => {
    props.setContactTags(tags);
  };

  const RenderTag = (props: any) => {
    let { tag, key, onRemove, getTagDisplayValue } = props;
    const label = getTagDisplayValue(tag);
    return label.length <= 0 ? (
      <></>
    ) : (
      <ChipDX color={"primary"} label={label} onDelete={() => onRemove(key)} />
    );
  };

  const RenderInput = (props: any) => {
    let { onChange, value, placeholder, addTag, label, ...other } = props;
    return (
      <Input
        placeholder={placeholder != null ? placeholder : t("Tag")}
        onChange={onChange}
        value={value}
        {...other}
      />
    );
  };

  return (
    <TagsInput
      value={props.tags}
      onChange={handleTagsChange}
      addOnBlur={true}
      renderInput={RenderInput}
      renderTag={RenderTag}
      maxTags={5}
      onlyUnique
      inputProps={{ placeholder: props.placeholder }}
    />
  );
};

export default TagInputDX;
