import { t } from "i18next";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import useWarehouseOrderBinService from "../../../shared/services/warehouseorderbinservice";
import useWarehouseService from "../../../shared/services/warehouseservice";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";

const WarehouseOrderBinForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const OrderBinId = state?.orderBinId;
  const handleClose = () => {
    navigate(-1);
  };
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouses } = useWarehouseService();
  const {  getWarehouseOrderBinById, addWarehouseOrderBin, updateWarehouseOrderBin } = useWarehouseOrderBinService();

  const defaultData = {
    prefix: "",
    numberOfBins: 0,
    warehouseId: "",
  };

  const [data, setData] = useState<any>(defaultData);
  const [warehouses, setWarehouses] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.prefix) newErrors["prefix"] = "Prefix is required";
    if (data.numberOfBins <= 0) newErrors["number"] = "Number is required";
    if (!data.warehouseId) newErrors["warehouse"] = "Warehouse is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const operation = OrderBinId
        ? updateWarehouseOrderBin(data)
        : addWarehouseOrderBin(data);
      operation
        .then(() => {
          setInfo(
            OrderBinId
              ? t("Bin updated successfully")
              : t("Bin added successfully")
          );
          handleClose();
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const getWarehouseList = async () => {
    getWarehouses()
      .then((res) => {
        setWarehouses(
          res.map((item: any) => {
            return { text: item.name, value: item.warehouseId };
          })
        );
      })
      .catch((err) => setError(err));
  };
  
  useEffect(() => {
    if (OrderBinId){
      setIsLoading(true);
      getWarehouseOrderBinById(OrderBinId)
        .then((res) => {
          setData(res);
          getWarehouseList();
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    else getWarehouseList();
  }, []);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Order Bin Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      {isLoading && <Loading sx={{ width: "100%", height: "100%"}} />}
      <GridDX
        container
        sx={{
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Prefix")}
            name="prefix"
            value={data?.prefix}
            onChange={handleInputChange}
            error={errors["prefix"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Number Of Bins")}
            name="numberOfBins"
            value={data?.numberOfBins}
            onChange={handleInputChange}
            type="number"
            error={errors["number"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
              label={t("Select Warehouse")}
              name="warehouseId"
              items={warehouses}
              value={data.warehouseId}
              onChange={handleInputChange}
              error={errors["warehouse"]}
            />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default WarehouseOrderBinForm;
