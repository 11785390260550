import useSecureAPI from "../hooks/usesecureapi";

const useSkuKitService = () => {
  const secureAPI = useSecureAPI();

  const getSkuKits = async () => {
    const response = await secureAPI.get("SkuKit");
    return response.data;
  };

  const getSkuKitsForCustomer = async (id: number) => {
    const response = await secureAPI.get(`SkuKit/forcustomer/${id}`);
    return response.data;
  };

  const getSkuKitById = async (id: number) => {
    const response = await secureAPI.get(`SkuKit/${id}`);
    return response.data;
  };

  const addSkuKit = async (data: any) => {
    const response = await secureAPI.post("SkuKit", data);
    return response.data;
  };

  const updateSkuKit = async (data: any) => {
    const response = await secureAPI.put(`SkuKit`, data);
    return response.data;
  };

  const archiveSkuKit = async (id: number) => {
    const response = await secureAPI.put(`SkuKit/archive/${id}`);
    return response.data;
  };

  return {
    getSkuKits,
    getSkuKitsForCustomer,
    getSkuKitById,
    addSkuKit,
    updateSkuKit,
    archiveSkuKit,
  };
};

export default useSkuKitService;
