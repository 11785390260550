import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import QuotationsItems from "../../units_components/quotationitems";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import useQuotationService from "../../../shared/services/quotationservice";
import TypographyDX from "../../layout/typographydx";
import BoxDX from "../../layout/boxdx";
import { getTotalAmount, numberWithCommas } from "../../../shared/globals";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";
import CheckBoxDX from "../../controls/checkboxdx";
import useCityService from "../../../shared/services/cityservice";
import useWarehouseService from "../../../shared/services/warehouseservice";
import ItemBox from "../../units_components/itembox";
import QuotationInvoiceReport from "../../pages/reports/quotation/quotationinvoicereport";
import { useLocation, useNavigate } from "react-router-dom";
import useSubscriptionPackageService from "../../../shared/services/subscriptionpackageservice";
const defaultQuotationValues = {
  requestForQuoteId: null,
  warehouseId: 0,
  preparedByUserId: "",
  quotationDate: moment(),
  dueDate: moment().add(7, "days"),
  customerName: "",
  customerAddress: "",
  customerCityId: 0,
  companyName: "",
  customerVatNumber: "",
  grossAmount: 0,
  vatPercent: 0,
  vatAmount: 0,
  netAmount: 0,
  quotationItems: [],
};

const QuotationForm = () => {
  const { state } = useLocation();
  const quotationId = state?.quotationId;
  const leadData = state?.leadData;
  const navigate = useNavigate();
  const { getQuotationById, addQuotation, updateQuotation } =
    useQuotationService();

  const { getCities } = useCityService();
  const { getWarehouses } = useWarehouseService();
  const { getSubscriptionPackages } = useSubscriptionPackageService();

  // Refs for input fields
  const customerNameRef = useRef<HTMLInputElement>(null);
  const customerAddressRef = useRef<HTMLInputElement>(null);

  const [quotationData, setQuotationData] = useState<any>(
    defaultQuotationValues
  );
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { setInfo, setError } = useNotificationContext();
  const [cities, setCities] = useState<any>([]);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState<any>([]);
  const [isFormEditable, setIsFormEditable] = useState(true);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setQuotationData({
      ...quotationData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setQuotationData({
      ...quotationData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!quotationData.customerName.trim().length)
      newErrors["customerName"] = t("Customer Name is required");
    if (!quotationData.customerAddress)
      newErrors["customerAddress"] = t("Customer Address is required");
    if (!quotationData.dueDate)
      newErrors["dueDate"] = t("Due Date is required");
    if (!quotationData.customerCityId)
      newErrors["customerCityId"] = t("Customer City is required");
    if (!quotationData.warehouseId)
      newErrors["warehouseId"] = t("Warehouse is required");
    if (!quotationData.customerVatNumber)
      newErrors["customerVatNumber"] = t("Customer VAT Number is required");

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      if (newErrors["customerName"]) {
        customerNameRef.current?.focus();
      } else if (newErrors["customerAddress"]) {
        customerAddressRef.current?.focus();
      }

      return false; // Form is not valid
    }

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: any) => {
    if (validateForm()) {
      setIsLoading(true);
      let updatedData = {
        ...quotationData,
        status: status === 2 ? 2 : 1,
      };
      // remove item_id from quotationItems to send to the server
      updatedData = {
        ...updatedData,
        quotationItems: quotationData.quotationItems.map((item: any) => {
          const { item_id, ...rest } = item;
          return rest;
        }),
      };

      // pop those items out which dont have an quotationItemId and but isDeleted flag
      updatedData = {
        ...updatedData,
        quotationItems: updatedData.quotationItems.filter(
          (item: any) => item.quotationItemId || !item.isDeleted
        ),
      };

      const operation = quotationId
        ? updateQuotation(updatedData)
        : addQuotation(updatedData);
      operation
        .then(() => {
          quotationId
            ? setInfo(t("Quotation updated successfully"))
            : setInfo(t("Quotation added successfully"));
          handleClose();
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const getDropDownData = () => {
    // get data from the server
    const p1 = getCities();
    const p2 = getWarehouses();
    const p3 = getSubscriptionPackages();
    Promise.all([p1, p2, p3]).then(
      ([cities, warehouses, subscriptionPackages]) => {
        setCities(
          cities.map((city: any) => ({
            text: city.cityName,
            value: city.cityId,
          }))
        );
        setWarehouses(
          warehouses.map((warehouse: any) => ({
            text: warehouse.name,
            value: warehouse.warehouseId,
          }))
        );
        setSubscriptionPackages(
          subscriptionPackages.map((item: any) => ({
            text: item.name,
            value: item,
          }))
        );
      }
    );
  };

  const getData: any = () => {
    if (leadData) {
      setQuotationData({
        ...defaultQuotationValues,
        customerName: leadData.fullname,
        customerAddress: leadData.address,
        customerCityId: leadData.city,
        companyName: leadData.companyName,
        customerVatNumber: leadData.vatNumber,
        requestForQuoteId: leadData.requestForQuoteId,
      });
    } else if (quotationId) {
      setIsLoading(true);
      getQuotationById(quotationId)
        .then((response) => {
          setQuotationData({
            ...response,
            quotationItems: response.quotationItems.map(
              (item: any, index: number) => ({
                ...item,
                item_id: index + 1 * 1000,
              })
            ),
          });
          setIsFormEditable(response.status === 1);
        })

        .catch((error) => {
          setError(t("Error getting quotation"));
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    setQuotationData((prevData: any) => ({
      ...prevData,
      ...getTotalAmount(prevData, prevData.quotationItems),
    }));
  }, [quotationData.vatPercent]);
  useEffect(() => {
    getData();
    getDropDownData();
  }, []);

  const handleClose = () => {
    navigate(-1);
  };
  const [showInvoicePreview, setShowInvoicePreview] = useState(false);

  return (
    <BoxDX sx={{ width: "100%" }}>
      {showInvoicePreview && (
        <QuotationInvoiceReport
          open={showInvoicePreview}
          onClose={() => setShowInvoicePreview(false)}
          quotationData={quotationData}
        />
      )}
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Quotation Details")}
        onSaveClick={onSave}
        onYesClick={handleClose}
        quotationStatus={quotationData.status}
        showSaveAndSubmit={
          (quotationData.status === 1 || !quotationData.status) && true
        }
        isPrintable={true}
        handleGeneratePDF={
          () => setShowInvoicePreview(true)
        }
      >
        {quotationId && (
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled
              label={t("Quotation Number")}
              name="quotationNumber"
              value={quotationData.quotationNumber}
              onChange={handleInputChange}
              error={errors["quotationNumber"]}
            />
          </GridDX>
        )}
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled
            label={t("Quotation Date")}
            name="quotationDate"
            value={moment(quotationData.quotationDate)}
            onChange={handleInputChange}
            error={errors["quotationDate"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled={!isFormEditable}
            label={t("Due Date")}
            name="dueDate"
            value={moment(quotationData.dueDate)}
            minDate={quotationData.quotationDate}
            onChange={(value: any) => handleDateChange(value, "dueDate")}
            error={errors["dueDate"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Customer Name")}
            name="customerName"
            value={quotationData.customerName}
            onChange={handleInputChange}
            error={errors["customerName"]}
            inputRef={customerNameRef} // Assign the ref
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Customer Address")}
            name="customerAddress"
            value={quotationData.customerAddress}
            onChange={handleInputChange}
            error={errors["customerAddress"]}
            inputRef={customerAddressRef} // Assign the ref
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Customer City")}
            items={cities}
            name="customerCityId"
            value={quotationData.customerCityId}
            onChange={handleInputChange}
            error={errors["customerCityId"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Company Name")}
            name="companyName"
            value={quotationData.companyName}
            onChange={handleInputChange}
            error={errors["companyName"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled={!isFormEditable}
            label={t("Customer VAT Number")}
            name="customerVatNumber"
            value={quotationData.customerVatNumber}
            onChange={handleInputChange}
            error={errors["customerVatNumber"]}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Warehouse")}
            items={warehouses}
            name="warehouseId"
            value={quotationData.warehouseId}
            onChange={handleInputChange}
            error={errors["warehouseId"]}
          />
        </GridDX>
        <GridDX item xs={8}>
          <CheckBoxDX
            disabled={!isFormEditable}
            label={t("VAT Included (15 %)")}
            name="vatPercent"
            checked={quotationData.vatPercent !== 0}
            onChange={(e: any) => {
              e.target.checked
                ? setQuotationData({ ...quotationData, vatPercent: 15 })
                : setQuotationData({ ...quotationData, vatPercent: 0 });
            }}
          />
        </GridDX>
        <GridDX item xs={4}>
          <GridDX container spacing={1}>
            <GridDX
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <TypographyDX variant="subtitle1">Gross Amount :</TypographyDX>
              <TypographyDX variant="subtitle1" fontWeight="bold">
                {numberWithCommas(quotationData.grossAmount.toFixed(2))}
              </TypographyDX>
            </GridDX>

            <GridDX
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <TypographyDX variant="subtitle1">Total Amount :</TypographyDX>
              <TypographyDX variant="subtitle1" fontWeight="bold">
                {numberWithCommas(quotationData.netAmount.toFixed(2))}
              </TypographyDX>
            </GridDX>
          </GridDX>
        </GridDX>
      </AddEditEmbeddedModal>

      <ItemBox>
        {quotationData.quotationItems && (
          <QuotationsItems
            quotationData={quotationData}
            setQuotationData={setQuotationData}
            getData={getData}
            setIsChanged={setIsChanged}
            isFormEditable={isFormEditable}
            subscriptionPackages={subscriptionPackages}
          />
        )}
      </ItemBox>
    </BoxDX>
  );
};

export default QuotationForm;
