import { t } from "i18next";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import useSkuCategoryService from "../../../shared/services/skucategoryservice";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import usePackingMaterialService from "../../../shared/services/packingmaterialservice";
import Loading from "../../layout/loading";
import SelectListDX from "../../controls/selectlistdx";
import TagInputDX from "../../controls/taginputdx";

const SKUCategoryForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const packageData = state?.packageData;
  const handleClose = () => {
    navigate(-1);
  };
  const { setInfo, setError } = useNotificationContext();
  const { addSkuCategory, updateSkuCategory } = useSkuCategoryService();
  const { getPackingMaterials } = usePackingMaterialService();

  const defaultData = {
    name: "",
    shortName: "",
    code: "",
    description: "",
    packingMaterialId: "",
    qcParameters: "",
  };

  const [data, setData] = useState<any>(defaultData);
  const [packingMaterials, setPackingMaterials] = useState<any>([]);
  const [tags, setTags] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleTagsChange = (tags: any) => {
    setIsChanged(true);
    setTags(tags);
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name) newErrors["name"] = "Name is required";
    if (!data.shortName) newErrors["shortName"] = "Short Name is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getData = async () => {
    setIsLoading(true);
    getPackingMaterials()
      .then((res) =>
        setPackingMaterials(
          res.map((item: any) => {
            return {
              text: item.name,
              value: item.packingMaterialId,
            };
          })
        )
      )
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsSaving(true);
      const saveData = {
        ...data,
        qcParameters: tags.toString(),
      };
      const operation = packageData
        ? updateSkuCategory(data.SKUCategoryId, saveData)
        : addSkuCategory(saveData);
      operation
        .then(() => {
          setInfo(
            packageData
              ? t("SKU Category updated successfully")
              : t("SKU Category added successfully")
          );
          handleClose();
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  useEffect(() => {
    if (packageData) {
      setData(packageData);
      setTags(packageData.qcParameters.split(","));
    }
    getData();
  }, []);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("SKU Category Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      <GridDX
        container
        sx={{
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        {isLoading && <Loading sx={{ width: "100%", height: "100%" }} />}
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={3}>
          <TextFieldDX
            label={t("Short Name")}
            name="shortName"
            value={data?.shortName}
            onChange={handleInputChange}
            error={errors["shortName"]}
          />
        </GridDX>
        <GridDX item xs={12} md={3}>
          <TextFieldDX
            label={t("Code")}
            name="code"
            value={data?.code}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Description")}
            name="description"
            value={data?.description}
            multiline
            rows={6}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("Packing Material")}
            items={packingMaterials}
            name="packingMaterialId"
            value={data.packingMaterialId}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TagInputDX
            placeholder={t("QC Parameters")}
            tags={tags}
            setContactTags={handleTagsChange}
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default SKUCategoryForm;
