import { t } from "i18next";
import ButtonDX from "../../../controls/buttondx";
import BoxDX from "../../../layout/boxdx";
import DataGridDX from "../../../layout/datagriddx";
import { Edit, Archive } from "@mui/icons-material";
import { GridColDef, GridRowParams, GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import TypeTranslator from "../../../../shared/typetranslator";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../../context/notificationcontext";
import useWarehouseRackService from "../../../../shared/services/warehouserackservice";
import ActionDropDownMenuDx from "../../../controls/dropdownmenudx";
import WarehouseRackBulkForm from "../../../units_forms/warehouse/warehouserackbulkform";

const RackList = (props: any) => {
  const {warehouseId} = props;
  const navigate = useNavigate();
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouseRacksByWarehouseId, archiveWarehouseRack } =
    useWarehouseRackService();
  const { getWarehouseCategory, getRackSize } = TypeTranslator();
  const [importForm, setImportForm] = useState(false);

  const [warehouseRacks, setWarehouseRacks] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const RackColumns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    { field: "code",
      headerName: `${t("Code")}`,
      maxWidth: 150,
    },
    { field: "areaName",
      headerName: `${t("Area")}`,
      maxWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "warehouseCategoryTypeId",
      headerName: `${t("Category")}`,
      minWidth: 200,
      valueGetter(params) {
          return getWarehouseCategory(params.row.warehouseCategoryTypeId);
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "rackSizeTypeId",
      headerName: `${t("Size")}`,
      minWidth: 150,
      valueGetter(params) {
          return getRackSize(params.row.rackSizeTypeId);
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "heightInCm",
      headerName: `${t("Height")}`,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "widthInCm",
      headerName: `${t("Width")}`,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "lengthInCm",
      headerName: `${t("Length")}`,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Edit")}
            showInMenu
            onClick={() => {
              onEdit(params.row);
            }}
            icon={<Edit />}
          />
        );

        actionsArray.push(
          <GridActionsCellItem
            label={t("Archive")}
            showInMenu
            onClick={() => {
              onArchive(params.row);
            }}
            icon={<Archive />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const onEdit = (data: any) => {
    navigate("/warehouserackdetails", {
      state: {
        warehouseId: warehouseId,
        warehouseRackId: data.id,
      },
    });
  };

  const toCreate = () => {
    navigate("/warehouserackdetails", {
      state: {
        warehouseId: warehouseId,
      },
    });
  };

  const onArchive = (data: any) => {
    setIsLoading(true);
    archiveWarehouseRack(data.warehouseRackId)
      .then(() => {
        setInfo(t("Warehouse Rack archived successfully"));
        getData();
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getData = () => {
    if (warehouseId) {
      setIsLoading(true);
      getWarehouseRacksByWarehouseId(warehouseId)
        .then((Racks) => {
          setWarehouseRacks(Racks);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onImport = () => {
    setImportForm(true);
  };

  const onClose = (refreshPage: boolean = false) => {
    setImportForm(false);

    if (refreshPage) getData();
  };

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.href = "";
    link.setAttribute("download", `UNITsTemplate.csv`);
    document.body.appendChild(link);
    link.click();
  };
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {importForm && <WarehouseRackBulkForm open={importForm} handleClose={onClose} warehouseId={warehouseId}/>}
      <BoxDX
        sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mb: 2 }}
      >
        <ActionDropDownMenuDx
          label={t("Import")}
          actions={[
            { label: t("Import Data"), onClick: onImport },
            {
              label: t("Download Template"),
              onClick: downloadTemplate,
            },
          ]}
          sx={{ mx: 2 }}
        />
        <ButtonDX
          variant="contained"
          color="primary"
          onClick={toCreate}
          sx={{ maxWidth: 120 }}
        >
          {t("Add Rack")}
        </ButtonDX>
      </BoxDX>

      <DataGridDX
        getRowId={(row: any) => row.warehouseRackId}
        rows={warehouseRacks}
        columns={RackColumns}
        loading={isLoading}
        disableRowSelectionOnClick={true}
        checkboxSelection={false}
        onRowClick={onEdit}
      />
    </>
  );
};

export default RackList;