import { useEffect, useRef, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { t } from "i18next";

import { useNotificationContext } from "../../../context/notificationcontext";

import GridDX from "../../layout/griddx";
import BoxDX from "../../layout/boxdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";

import useCityService from "../../../shared/services/cityservice";
import ItemBox from "../../units_components/itembox";
import { InputAdornment } from "@mui/material";
import CheckBoxDX from "../../controls/checkboxdx";
import useWarehouseService from "../../../shared/services/warehouseservice";
import TypeTranslator from "../../../shared/typetranslator";
import DynamicTabs from "../../layout/dynamictab";
import AreaList from "./tab_content/arealist";
import RackList from "./tab_content/racklist";
import LocationList from "./tab_content/locationlist";

const WarehouseDetails = (props: any) => {
  const { state } = useLocation();
  const warehouseId = state?.warehouseId;
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouseById, addWarehouse, updateWarehouse } =
    useWarehouseService();
  const { getCities } = useCityService();
  const { warehouseTypes} =
    TypeTranslator();
  

  const defaultValues = {
    warehouseId: 0,
    name: "",
    number: "",
    streetAddress: "",
    cityId: null,
    locationCoord: "",
    pocPhone: "",
    pocName: "",
    ownedByUnits: true,
    ownerCompanyName: "",
    ownerPocName: "",
    ownerPocPhone: "",
    totalSpaceInCbm: 0,
    warehouseTypeId: null,
    licenseInfo: "",
  };

  const [warehouseData, setWarehouseData] = useState<any>(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<any>([]);
  const [isFormEditable, setIsFormEditable] = useState(true);

  // Refs for input fields
  const pocNameRef = useRef<HTMLInputElement>(null);
  const pocPhoneRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setWarehouseData({
      ...warehouseData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!warehouseData.name) {
      newErrors["name"] = t("Name is required");
    }
    if (!warehouseData.number) {
      newErrors["number"] = t("Number is required");
    }
    if (!warehouseData.streetAddress) {
      newErrors["streetAddress"] = t("Address is required");
    }
    if (!warehouseData.cityId) {
      newErrors["cityId"] = t("City is required");
    }
    if (!warehouseData.warehouseTypeId) {
      newErrors["warehouseTypeId"] = t("Warehouse Type is required");
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const onSave = (status: any) => {
    if (!isDataChanged) {
      handleClose();
      return;
    }
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);
      const operation = warehouseId
        ? updateWarehouse(warehouseData)
        : addWarehouse(warehouseData);
      operation
        .then(() => {
          warehouseId
            ? setInfo(t("Warehouse updated successfully"))
            : setInfo(t("Warehouse added successfully"));
          handleClose();
        })
        .catch((error: any) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };


  const getDropDownData = () => {
    // get data from the server
    const p1 = getCities();
    Promise.all([p1]).then(([cities]) => {
      setCities(
        cities.map((city: any) => ({ text: city.cityName, value: city.cityId }))
      );
    });
  };

  const getData = () => {
    if (warehouseId) {
      setIsLoading(true);
      getWarehouseById(warehouseId)
        .then((warehouse) => {
          setWarehouseData(warehouse);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getData();
    getDropDownData();
  }, []);
  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [warehouseData]);

  return (
    <BoxDX sx={{ width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Name")}
              name="name"
              value={warehouseData.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Number")}
              name="number"
              value={warehouseData.number}
              onChange={handleInputChange}
              error={errors["number"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Address")}
              name="streetAddress"
              value={warehouseData.streetAddress}
              onChange={handleInputChange}
              error={errors["streetAddress"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("City")}
              items={cities}
              name="cityId"
              value={warehouseData.cityId}
              onChange={handleInputChange}
              error={errors["cityId"]}
              InputLabelProps={{
                shrink: warehouseData.cityId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              ref={pocNameRef}
              label={t("POC Name")}
              name="pocName"
              value={warehouseData.pocName}
              onChange={handleInputChange}
              error={errors["pocName"]}
            />
          </GridDX>

          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              ref={pocPhoneRef}
              label={t("POC Phone")}
              name="pocPhone"
              value={warehouseData.pocPhone}
              onChange={handleInputChange}
              error={errors["pocPhone"]}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>

          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Total Space")}
              name="totalSpaceInCbm"
              value={warehouseData.totalSpaceInCbm}
              onChange={handleInputChange}
              error={errors["totalSpaceInCbm"]}
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: (
                  <InputAdornment position="end">CBM</InputAdornment>
                ),
              }}
              type="number"
              // Disable spin buttons for number input
              className="noSpinButtons"
            />
          </GridDX>

          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Warehouse Type")}
              name="warehouseTypeId"
              value={warehouseData.warehouseTypeId}
              onChange={handleInputChange}
              error={errors["warehouseTypeId"]}
              items={warehouseTypes}
              InputLabelProps={{
                shrink: warehouseData.warehouseTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("License Info")}
              name="licenseInfo"
              value={warehouseData.licenseInfo}
              onChange={handleInputChange}
              error={errors["licenseInfo"]}
            />
          </GridDX>

          {!warehouseData.ownedByUnits && (
            <>
              <GridDX item xs={12} md={4}>
                <TextFieldDX
                  disabled={!isFormEditable}
                  label={t("Owner Company Name")}
                  name="ownerCompanyName"
                  value={warehouseData.ownerCompanyName}
                  onChange={handleInputChange}
                  error={errors["ownerCompanyName"]}
                />
              </GridDX>
              <GridDX item xs={12} md={4}>
                <TextFieldDX
                  disabled={!isFormEditable}
                  label={t("Owner POC Name")}
                  name="ownerPocName"
                  value={warehouseData.ownerPocName}
                  onChange={handleInputChange}
                  error={errors["ownerPocName"]}
                />
              </GridDX>
              <GridDX item xs={12} md={4}>
                <TextFieldDX
                  disabled={!isFormEditable}
                  label={t("Owner POC Phone")}
                  name="ownerPocPhone"
                  value={warehouseData.ownerPocPhone}
                  onChange={handleInputChange}
                  error={errors["ownerPocPhone"]}
                  type="number"
                  // Disable spin buttons for number input
                  className="noSpinButtons"
                />
              </GridDX>
            </>
          )}
          <GridDX item xs={12} md={4}>
            <CheckBoxDX
              disabled={!isFormEditable}
              label={t("Owned By Units")}
              name="ownedByUnits"
              checked={warehouseData.ownedByUnits}
              onChange={handleInputChange}
            />
          </GridDX>
        </GridDX>
      </ItemBox>
      {warehouseId && (
        <ItemBox>
        <DynamicTabs
          tabLabels={[t("Areas"), t("Racks"), t("Locations")]}
          tabContents={[
            <AreaList warehouseId={warehouseId}/>, <RackList warehouseId={warehouseId}/>, <LocationList warehouseId={warehouseId}/>
          ]}
        />
      </ItemBox>
      )}
    </BoxDX>
  );
};

export default WarehouseDetails;
