import { useEffect, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import AddEditModalDX from "../../business/addeditmodaldx";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";

const PickerModal = (props: any) => {
  const { open, handleClose, outboundRequestId } = props;
  const { getPickerList, assignPicker } = useOutboundRequestService();
  const [pickerLists, setPickerLists] = useState<any>([]);
  const [selectedPicker, setSelectedPicker] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setError, setInfo } = useNotificationContext();
  const [errors, setErrors] = useState<any>({});

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      assignPicker({ outboundRequestId, pickerUserId:selectedPicker })
        .then((res) => {
          setInfo(t("Picker assigned successfully"));
          handleClose(true);
        })
        .catch((err) => {
          console.log(err);
          setError(err.message);
        })
        .finally(() => {
          setIsLoading(false);
          handleClose();
        });
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!selectedPicker) {
      newErrors.pickerId = t("Please select a picker");
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    getPickerList()
      .then((res) => {
        const data = res.map((item: any) => ({
          value: item.id,
          text: item.fullName,
        }));
        setPickerLists(data);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AddEditModalDX
      open={open}
      title={t("Assign Picker")}
      handleClose={handleClose}
      maxWidth="sm"
      isSaving={isLoading}
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      <GridDX container rowSpacing={3} py={1} columnSpacing={1}>
        <GridDX item xs={12}>
          <SelectListDX
            label={t("Select Picker")}
            value={selectedPicker}
            onChange={(e: any) => setSelectedPicker(e.target.value)}
            items={pickerLists}
            error={errors.pickerId}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default PickerModal;
