import { useEffect, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { Fab, InputAdornment } from "@mui/material";
import { Delete } from "@mui/icons-material";
import ButtonDX from "../../controls/buttondx";
import TypographyDX from "../../layout/typographydx";
import AddEditModalDX from "../../business/addeditmodaldx";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";

const ItemPackingForm = (props: any) => {
  const { data, handleClose, open } = props;
  const { setError, setInfo } = useNotificationContext();
  const { packSku } = useOutboundRequestService();
  const [packedQuantity, setPackedQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const onSave = async () => {
    if (validateForm()) {
      const saveData = {
        outboundRequestId: data.outboundRequestId,
        outboundRequestSkus: [
          {
            skuId: data.skuId,
            quantity: packedQuantity,
            outboundRequestSkuId: data.outboundRequestSkuId


          },
        ]
      };
      setIsLoading(true);
      packSku(saveData)
        .then((res) => {
          setInfo(t("Sku packed successfully"));
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };



  const validateForm = () => {
    const newErrors: any = {};

    if (packedQuantity === 0) {
      newErrors.packedQuantity = t("Please enter quantity");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };






  return (
    <AddEditModalDX
      open={open}
      title={t("Pack Item")}
      handleClose={handleClose}
      maxWidth="md"
      isSaving={isLoading}
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      <GridDX container rowSpacing={2} columnSpacing={1}>
        <GridDX item xs={12}>
          <TypographyDX fontWeight={"bold"}>
            {`${t("Selected SKU")}: `}
          </TypographyDX>
          <TypographyDX mx={1}>{data.skuName}</TypographyDX>
        </GridDX>
        {/* <GridDX item xs={12} mb={1}>
          <TypographyDX fontWeight={"bold"}>{`${t("Code")}: `}</TypographyDX>
          <TypographyDX mx={1}>{data.skuCode}</TypographyDX>
        </GridDX> */}

        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("No. of Units")}
            name="packedQuantity"
            value={packedQuantity}
            onChange={(event: any) => {
              let newValue = parseInt(event.target.value);
              if (newValue > data.quantity - data.packedQuantity) {
                newValue = data.quantity - data.packedQuantity;
              }
              setPackedQuantity(newValue);
            }}

            type="number"
            error={errors[`packedQuantity`]}
            // Disable spin buttons for number input
            className="noSpinButtons"
            InputProps={{
              // endAdornment: <InputAdornment position="end">{`/${data.usableUnits - data.putAwayUnits}`}</InputAdornment>,
              endAdornment: (
                <InputAdornment position="end">{`/${data.quantity - data.packedQuantity || 0}`}</InputAdornment>
              ),
            }}
          />
        </GridDX>

      </GridDX>
    </AddEditModalDX>
  );
};

export default ItemPackingForm;
