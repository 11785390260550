import useSecureAPI from "../hooks/usesecureapi";

const useTenentService = () => {
  const secureAPI = useSecureAPI();

  const getTenents = async () => {
    const response = await secureAPI.get("Tenent");
    return response.data;
  };

  const getTenentById = async (id: number) => {
    const response = await secureAPI.get(`Tenent/${id}`);
    return response.data;
  };

  const archiveTenent = async (id: number) => {
    const response = await secureAPI.put(`Tenent/archive/${id}`);
    return response.data;
  };

  return {
    getTenents,
    getTenentById,
    archiveTenent
  };
};

export default useTenentService;
