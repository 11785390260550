import { useEffect, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import AddEditModalDX from "../../business/addeditmodaldx";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import TextFieldDX from "../../controls/textfielddx";

const ReturnedModal = (props: any) => {
  const { open, handleClose, outboundRequestId } = props;
  const { moveToReturned } = useOutboundRequestService();
  const [returnReason, setReturnReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setError, setInfo } = useNotificationContext();
  const [errors, setErrors] = useState<any>({});

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      moveToReturned({ outboundRequestId, returnReason })
        .then((response: any) => {
          setInfo(t("Request moved to Returned successfully"));
          handleClose(true);
        })
        .catch((error: any) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!returnReason) {
      newErrors.returnReason = t("Please enter return reason");
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  return (
    <AddEditModalDX
      open={open}
      title={t("Move to Returned")}
      handleClose={handleClose}
      maxWidth="sm"
      isSaving={isLoading}
      onSaveClick={onSave}
      onYesClick={handleClose}
      
    >
      <GridDX container rowSpacing={3} py={1} columnSpacing={1}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Return Reason")}
            name="returnReason"
            value={returnReason}
            onChange={(e: any) => setReturnReason(e.target.value)}
            error={errors["returnReason"]}
            multiline
            minRows={2}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default ReturnedModal;
