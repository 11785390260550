import { Divider } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import moment from "moment";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DATE_FORMAT } from "../../../shared/globals";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import GridSearchFilterTextbox from "../../business/gridsearchfiltertextbox";
import ButtonDX from "../../controls/buttondx";
import BoxDX from "../../layout/boxdx";
import DataGridDX from "../../layout/datagriddx";
import GridDX from "../../layout/griddx";
import TypographyDX from "../../layout/typographydx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import ItemPackingForm from "./itempackingform";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import placeholderskuimage from "../../../assets/placeholderskuimage5050.png";
import TypeTranslator from "../../../shared/typetranslator";

const PackingForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const requestId = state?.requestId;
  const { getOutboundRequestById, markAsPacked } = useOutboundRequestService();
  const { setInfo, setError } = useNotificationContext();
  const { getPackagingTypeValue } = TypeTranslator()

  const ItemsColumns: GridColDef[] = [
    {
      field: "skuPictureURL",
      headerName: t("Image"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <BoxDX sx={{ p: 1 }}>
            <img
              src={params.row.skuPictureURL || placeholderskuimage}
              alt={params.row.skuId}
              style={{ width: 50, height: 50 }}
            />
          </BoxDX>
        );
      },
    },
    {
      field: "skuName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "packagingType",
      headerName: `${t("Packaging Type")}`,
      flex: 1,
    },
    {
      field: "quantity",
      headerName: `${t("Quantity")}`,
      minWidth: 150,
    },
    {
      field: "packedQuantity",
      headerName: `${t("Packed Quantity")}`,
      minWidth: 150,
    },
    {
      field: "expiryDate",
      headerName: `${t("Expiry Date")}`,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      valueFormatter(params) {
        return params.value ? moment(params.value).format(DATE_FORMAT) : "";
      },
    },
    {
      headerName: t("Actions"),
      field: "actions",
      align: "center",
      headerAlign: "center",
      minWidth: 135,
      renderCell(params) {
        return (
          <ButtonDX
            sx={{ minWidth: 110, m: 1 }}
            disabled={params.row.isPacked}
            onClick={() => {
              setSelectedSKU(params.row);
              setOpenForm(true);
            }}
          >
            {t("Pack")}
          </ButtonDX>
        );
      },
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const [requestData, setRequestData] = useState<any>(null);
  const [selectedSKU, setSelectedSKU] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [skus, setSKUs] = useState<any>([]);
  const [totalPackedSkus, setTotalPackedSkus] = useState<any>(0);
  const [isChanged, setIsChanged] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    navigate("/outboundrequests");
  };

  const onSave = () => {
    if (totalPackedSkus === skus.length) {
      setIsLoading(true);
      markAsPacked(requestData.outboundRequestId)
        .then((res) => {
          setInfo(t("Items packed successfully!"));
          navigate(-1);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    } else setError(t("Please pack all the items."));
  };

  const onClose = (confirm: boolean = false) => {
    if (confirm) getData();

    setSelectedSKU(null);
    setOpenForm(false);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(skus);
      return;
    }
    const newRows = skus.filter(
      (row: any) =>
        row.skuName.toLowerCase().includes(value.toLowerCase()) ||
        row.quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
        row.packagingType.toLowerCase().includes(value.toLowerCase())

    );
    setRows(newRows);
  };

  const getData = () => {
    setIsLoading(true);
    getOutboundRequestById(requestId)
      .then((res: any) => {
        let packed = 0;
        const skuList = res.outboundRequestSkus.map((item: any) => {
          const isPacked = item.quantity === item.packedQuantity;
          if (isPacked) packed++;
          return {
            ...item,

            expiryDate: item.expiryDate
              ? moment(item.expiryDate).format(DATE_FORMAT)
              : null,
            isPacked,
            packagingType: getPackagingTypeValue(item.packagingTypeId),
          };
        });
        setRequestData(res);
        setTotalPackedSkus(packed);
        setSKUs(skuList);
        setRows(skuList);
      })
      .catch((err: any) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        onSaveClick={onSave}
        saveLabel={t("Mark as Packed")}
        title={t("Packing Panel")}
        onYesClick={handleClose}
        isPrintable={false}
      >
        {openForm && (
          <ItemPackingForm
            open={openForm}
            data={selectedSKU}
            handleClose={onClose}
          />
        )}
        <GridDX container alignItems="center" columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={6} justifyContent="space-between">
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t(
                "Request No."
              )}`}</TypographyDX>
              <TypographyDX>{requestData?.outboundRequestId}</TypographyDX>
            </BoxDX>
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t(
                "Total SKUs"
              )}`}</TypographyDX>
              <TypographyDX>{skus.length}</TypographyDX>
            </BoxDX>
          </GridDX>
          <Divider sx={{ my: 1, width: "100%" }} />
          <GridDX item xs={12}>
            <TypographyDX fontWeight={"bold"}>{`${t(
              "Packed Skus"
            )} :`}</TypographyDX>
            <TypographyDX mx={2}>{`${totalPackedSkus}/${skus.length} ${t(
              "SKUs"
            )}`}</TypographyDX>
          </GridDX>
          <GridDX item xs={4}>
            <GridSearchFilterTextbox
              placeholder={t("Search")}
              sx={{
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px 0px #0000000F",
                border: "1px solid #D0D5DD",
              }}
              setGridFilterCriteria={setGridFilterCriteria}
            />
          </GridDX>
          <GridDX item xs={12}>
            <DataGridDX
              getRowId={(row: any) => row.outboundRequestSkuId}
              rows={rows}
              columns={ItemsColumns}
              loading={isLoading}
              disableRowSelectionOnClick={true}
              checkboxSelection={false}
            />
          </GridDX>
        </GridDX>
      </AddEditEmbeddedModal>
    </BoxDX>
  );
};

export default PackingForm;
