import { t } from "i18next";
import { useState } from "react";
import { useNotificationContext } from "../../../context/notificationcontext";
import useLeadService from "../../../shared/services/leadservice";
import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";

const NotesModal = (props: any) => {
    const [note, setNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const { updateLead } = useLeadService();
    const { setInfo,setError } = useNotificationContext();
  
    const validateForm = () => {
      const newErrors: any = {};
      setErrors(newErrors);
  
      return Object.keys(newErrors).length === 0;
    };
  
    const onSave = async () => {
      if (validateForm()) {
        setIsLoading(true);
        const status = props.saveLabel === "Mark as Junk" ? 3 : 4;
  
        updateLead({ ...props.leadData, requestStatus: status, notes: note })
          .then((response) => {
            setInfo("Lead updated successfully");
            props.onClose();
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };
  
    const handleClose = () => {
      props.onClose();
    };
  
    const handleInputChange = (e: any) => {
      const { name, value, checked, type } = e.target;
      setIsChanged(true);
      setNote(value);
    };
  
    return (
      <AddEditModalDX
        open={props.open}
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Add Notes")}
        maxWidth="sm"
        onSaveClick={onSave}
        saveLabel={props.saveLabel}
        saveBtncolor={props.saveBtncolor}
        onYesClick={handleClose}
      >
        <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
          <GridDX item xs={12}>
            <TextFieldDX
              label={t("Notes")}
              name="note"
              value={note}
              onChange={handleInputChange}
              multiline
              rows={3}
            />
          </GridDX>
        </GridDX>
      </AddEditModalDX>
    );
  };

export default NotesModal;