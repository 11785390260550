import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useAuthContext } from "../../context/authcontext";
import DynamicTabs from "../../components/layout/dynamictab";
import ItemBox from "../../components/units_components/itembox";
import AllOutboundRequests from "./tab_content/allrequests";
import UnAssignedPickerList from "./tab_content/unassignedpickerlist";
import AssignedPickerList from "./tab_content/assignedpickerlist";
import PickedList from "./tab_content/pickedlist";
import OutboundQcList from "./tab_content/outboundqclist";
import PackedList from "./tab_content/packedlist";
import DispatchedList from "./tab_content/dispatchedlist";
import SelfCollectionList from "./tab_content/selfcollectionlist";
import OutboundCompletedList from "./tab_content/outboundcompletedlist";
import ReturnedList from "./tab_content/returnedlist";
import OnHoldList from "./tab_content/onholdlist";
import CancelledList from "./tab_content/cancelledlist";

const OutboundRequests = () => {
  const { userData } = useAuthContext();
  const { setAddRecordfn } = useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const toCreate = () => {
    navigate("/outbounddetails", {
      state: {
        requestId: null,
      },
    });
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
  }, [i18n.language]);

  return (
    <ItemBox>
      <DynamicTabs
        tabLabels={[
          t("All"),
          t("Picker Unassigned"),
          t("Picker Assigned"),
          t("Picked"),
          // t("QC"),
          t("Packed"),
          t("Dispatched"),
          t("Self Collection"),
          t("Completed"),
          t("Returned"),
          // t("Problem"),
          t("On Hold"),
          t("Cancelled"),
        ]}
        tabContents={[
          <AllOutboundRequests />,
          <UnAssignedPickerList />,
          <AssignedPickerList />,
          <PickedList />,
          // <OutboundQcList />,
          <PackedList />,
          <DispatchedList />,
          <SelfCollectionList />,
          <OutboundCompletedList />,
          <ReturnedList />,
          <OnHoldList />,
          <CancelledList />,
        ]}
      />
    </ItemBox>
  );
};

export default OutboundRequests;
