import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import { useNotificationContext } from "../../../context/notificationcontext";
import { getTotalAmount } from "../../../shared/globals";
import BoxDX from "../../layout/boxdx";
import TypographyDX from "../../layout/typographydx";

const PutawayRequestItemForm = (props: any) => {
  const { requestItemId, requestData, setRequestData, handleClose } = props;
  const { t } = useTranslation();
  const defaultValues = {
    item_id: requestData.items.length + 1,
    description: "",
    unit_price: 0,
    quantity: 1,
    discount_percent: 0,
    discount_amount: 0,
    total_amount: 0,
  };
  const [itemData, setItemData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { setInfo } = useNotificationContext();

  useEffect(() => {
    getData();
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (!itemData.description)
      newErrors.description = t("Description is required");

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getData = async () => {
    requestItemId &&
      setItemData(
        requestData.items.find((item: any) => item.item_id == requestItemId)
      );
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setItemData({
      ...itemData,
      [name]: type == "checkbox" ? checked : value,
    });
  };
  useEffect(() => {
    // set total amount and discount amount
    const discount_amount =
      itemData.unit_price *
      itemData.quantity *
      (itemData.discount_percent / 100);
    const total_amount =
      itemData.unit_price * itemData.quantity - discount_amount;
    setItemData({
      ...itemData,
      total_amount: total_amount,
      discount_amount: discount_amount,
    });
  }, [itemData.quantity, itemData.unit_price, itemData.discount_percent]);

  useEffect(() => {
    getData();
  }, []);

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      const newItems = [...requestData.items];
      const index = newItems.findIndex(
        (item: any) => item.item_id === itemData.item_id
      );
      if (index > -1) {
        newItems[index] = itemData;
        setInfo("Item updated successfully");
      } else {
        newItems.push({
          ...itemData,
          movement_request_id: requestData.movement_request_id,
          item_id: newItems.length + 1,
        });
        setInfo("Item added successfully");
      }
      const updatedData = {
        ...requestData,
        items: newItems,
      };
      setRequestData({ ...updatedData, ...getTotalAmount(updatedData, updatedData.items) });
      setIsChanged(false);
      setIsLoading(false);
      handleClose();
      props.setIsChanged(true);
    }
  };

  return (
    <AddEditModalDX
      open={true}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Item")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Description")}
            name="description"
            value={itemData.description}
            onChange={handleInputChange}
            error={errors["description"]}
            multiline
            minRows={2}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            label={t("Unit Price")}
            name="unit_price"
            value={itemData.unit_price}
            onChange={handleInputChange}
            error={errors["unit_price"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            type="number"
            InputProps={{ inputProps: { min: 1, max: 10 } }}
            label={t("Quantity")}
            name="quantity"
            value={itemData.quantity}
            onChange={handleInputChange}
            error={errors["quantity"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            type="number"
            InputProps={{ inputProps: { min: 0, max: 50 } }}
            label={t("Discount Percent")}
            name="discount_percent"
            value={itemData.discount_percent}
            onChange={handleInputChange}
            error={errors["discount_percent"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            disabled
            label={t("Discount Amount")}
            name="discount_amount"
            value={itemData.discount_amount}
            onChange={handleInputChange}
            error={errors["discount_amount"]}
          />
        </GridDX>
        <GridDX item xs={6}></GridDX>
        <GridDX item xs={6} justifyContent="flex-end">
          {/* <TypographyDX sx={{ textAlign: "right", fontSize: "18px" }}>
            Total Amount:
          </TypographyDX>
          <TypographyDX
            sx={{ textAlign: "right", fontSize: "18px", fontWeight: "bold" }}
          >
            {quotationItemData.total_amount}
          </TypographyDX> */}
          <BoxDX
            sx={{
              display: "flex",
              alignItems: "end",
              borderBottom: "2px solid",
              gap: 2,
            }}
          >
            <TypographyDX sx={{ fontSize: "18px" }}>Total Amount:</TypographyDX>
            <TypographyDX sx={{ fontSize: "18px", fontWeight: "bold" }}>
              {itemData.total_amount}
            </TypographyDX>
          </BoxDX>
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default PutawayRequestItemForm;
