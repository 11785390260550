import useSecureAPI from "../hooks/usesecureapi";

const useSkuCategoryService = () => {
  const secureAPI = useSecureAPI();

  const getSkuCategories = async () => {
    const response = await secureAPI.get("SkuCategory");
    return response.data;
  };

  const getSkuCategoryById = async (id: number) => {
    const response = await secureAPI.get(`SkuCategory/${id}`);
    return response.data;
  };

  const addSkuCategory = async (data: any) => {
    const response = await secureAPI.post("SkuCategory", data);
    return response.data;
  };

  const updateSkuCategory = async (id: number, data: any) => {
    const response = await secureAPI.put(`SkuCategory`, data);
    return response.data;
  };

  const archiveSkuCategory = async (id: number) => {
    const response = await secureAPI.put(`SkuCategory/archive/${id}`);
    return response.data;
  };

  return {
    getSkuCategories,
    getSkuCategoryById,
    addSkuCategory,
    updateSkuCategory,
    archiveSkuCategory,
  };
};

export default useSkuCategoryService;
