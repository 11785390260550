import { useEffect, useState } from "react";
import { t } from "i18next";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import { useAuthContext } from "../../../context/authcontext";
import Loading from "../../layout/loading";
import TypographyDX from "../../layout/typographydx";
import useSKUService from "../../../shared/services/skuservice";
import useSkuKitService from "../../../shared/services/skukitservice";
import AutoCompleteListDX from "../../controls/autocompletelistdx";
import ButtonDX from "../../controls/buttondx";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import DataGridDX from "../../layout/datagriddx";
import { Delete } from "@mui/icons-material";
import useUserService from "../../../shared/services/userservices";
import SelectListDX from "../../controls/selectlistdx";
import BoxDX from "../../layout/boxdx";

const SkuKitForm = (props: any) => {
  const { id, handleClose } = props;
  const { getSkuKitById, addSkuKit, updateSkuKit } = useSkuKitService();
  const { getSKUsForCustomer } = useSKUService();
  const { userData } = useAuthContext();
  const { getActiveUsers } = useUserService();
  const { setInfo, setError } = useNotificationContext();
  
  const defaultData = {
    customerId: userData?.customerId,
    name: "",
    skuNumber: "",
    skuKitItems: []
  };

  const buildActions = (params: GridRowParams) => {
    let actionsArray = [];
    
    actionsArray.push(
        <GridActionsCellItem
          label={t("Delete")}
          showInMenu
          onClick={() => {
            for (let item in rows) {
              const sku = rows[item];
              if (sku.skuId === params.id) {
                const newRows = [...rows];
                newRows.splice(parseInt(item), 1);
                setRows([...newRows]);
              }
            }
          }}
          icon={<Delete />}
        />
      );
    return actionsArray;
  };

  const columns: GridColDef[] = [
    {
      field: "skuName",
      headerName: `${t("Name")}`,
      flex: 1
    },
    {
      field: "numberOfUnits",
      headerName: `${t("Number Of Units")}`,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: buildActions,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    }
  ];

  const [rows, setRows] = useState<any>([]);
  const [skuKitItemsFromApi, setSkuKitItemsFromApi] = useState<any>([]);
  const [data, setData] = useState<any>(defaultData);
  const [skus, setSKUs] = useState<any>([]);
  const [selectedSKU, setSelectedSKU] = useState<any>(null);
  const [units, setUnits] = useState(0);
  const [customerId, setCustomerId] = useState<any>(null);
  const [customers, setCustomers] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCustomerChange = (e: any) => {
    const { value } = e.target;
    setCustomerId(value);
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (rows.length === 0) newErrors["sku"] = "Atleast one SKU required";
    if (!customerId && !id && userData.userType === "S") newErrors["customerId"] = "Select a Customer";
    if (!data.name) newErrors["name"] = "Name is required";
    if (!data.skuNumber) newErrors["skuNumber"] = "Sku Number is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getSKUs = async (c_id: number) => {
    setIsLoading(false);
    getSKUsForCustomer(c_id)
      .then((res) => {
        setSKUs(
          res.map((sku: any) => ({
            text: sku.name,
            value: sku.skuId,
          }))
        );
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const getCustomers = async () => {
    getActiveUsers()
      .then((res) => {
        const users = res.filter((user: any) => user.userType === "C");
        setCustomers(
          users.map((user: any) => {
            return { text: user.fullName, value: user.customerId };
          })
        );
      })
      .catch((err) => setError(err));
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsSaving(true);

      let skuItems: any[] = [];

      const addedSKUs = rows.filter((row: any) => !skuKitItemsFromApi.some((obj: any) => (row.skuId === obj.skuId) && (row.numberOfUnits == obj.numberOfUnits)));
      const deletedSKUs = skuKitItemsFromApi.filter((obj: any) => !rows.some((row: any) => (row.skuId === obj.skuId) && (row.numberOfUnits == obj.numberOfUnits)));
      
      addedSKUs.forEach((sku: any) => skuItems.push(sku));
      deletedSKUs.forEach((sku: any) => skuItems.push({...sku, isDeleted: true}));

      const saveData = {
        ...data,
        customerId: (userData.userType === "S" && !id) ? customerId : data.customerId,
        skuKitItems: skuItems
      }
      console.log(skuItems);
      console.log(saveData);
      const operation = id ? updateSkuKit(saveData) : addSkuKit(saveData);
      operation
        .then((response) => {
          setInfo(
            id ? t("SKU Kit updated successfully") : t("SKU Kit added successfully")
          );
          if (props.customerId) {
            handleClose({ skuKitId: response?.skuKitId, name: response?.name });
          } else {
            handleClose(true);
          }
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  useEffect(() => {
    userData.userType === "S" && getCustomers();
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getSkuKitById(id)
        .then((res) => {
          setData(res);
          setRows(res.skuKitItems);
          setSkuKitItemsFromApi(res.skuKitItems);
          getSKUs(res.customerId);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    else {
      userData.userType === "S" && getCustomers();
    } 
  }, [id]);

  useEffect(() => {
    if (!id) {
      const c_id = userData.customerId ? userData.customerId : customerId;
      if (c_id) getSKUs(c_id);
    }
  }, [customerId])

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("SKU Kit Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      {isLoading && <Loading />}
      <GridDX container columnSpacing={1} rowSpacing={2}>
      {userData.userType === "S" && !(id) && (
          <GridDX
            item
            xs={12}
            sx={{
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <BoxDX width={350}>
              <SelectListDX
                label={t("Select Customer")}
                name="customerId"
                items={customers}
                value={customerId}
                onChange={handleCustomerChange}
                error={errors["customerId"]}
              />
            </BoxDX>
          </GridDX>
        )}
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("SKU Number")}
            name="skuNumber"
            value={data?.skuNumber}
            onChange={handleInputChange}
            error={errors["skuNumber"]}
          />
        </GridDX>
        <GridDX item xs={12} md={8}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TypographyDX sx={{ fontSize: 18, fontWeight: "bold" }} >
            {t("Add SKUs")}
          </TypographyDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <AutoCompleteListDX
            label={t("SKU")}
            list={skus}
            name="skuId"
            value={selectedSKU?.skuId}
            onChange={(e: any) => {
              const { name, value, text } = e.target;
              setIsChanged(true);
              setSelectedSKU({
                skuId: value,
                name: text
              });
            }}
            error={errors["sku"]}
          />
        </GridDX>
        <GridDX item xs={12} md={3}>
          <TextFieldDX
            label={t("No. Of Units")}
            name="numberOfUnits"
            value={units}
            onChange={(e: any) => {
              const { name, value } = e.target;
              setIsChanged(true);
              setUnits(value);
            }}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={5} justifyContent="flex-end" alignItems="center">
          <ButtonDX
            disabled={selectedSKU === null || !(units > 0)}
            sx={{ maxWidth: 120 }} 
            onClick={() => {
              const newRows = [...rows];
              newRows.push({
                skuId: selectedSKU.skuId,
                skuName: selectedSKU.name,
                numberOfUnits: units
              });
              setRows([...newRows]);
              setSelectedSKU(null);
              setUnits(0);
            }}
          >
            {t("Add")}
          </ButtonDX>
        </GridDX>
        <GridDX item xs={12}>
        <DataGridDX
          getRowId={(row: any) => row.skuId}
          rows={rows}
          columns={columns}
          loading={isLoading}
          checkboxSelection={false}
          disableRowSelectionOnClick={true}
          hideColumnsOption={false}
          rowsPerPage={10}
        />
      </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default SkuKitForm;
