import useSecureAPI from "../hooks/usesecureapi";

const usePackingMaterialService = () => {
  const secureAPI = useSecureAPI();

  const getPackingMaterials = async () => {
    const response = await secureAPI.get("PackingMaterial");
    return response.data;
  };

  const getPackingMaterialById = async (id: number) => {
    const response = await secureAPI.get(`PackingMaterial/${id}`);
    return response.data;
  };

  const addPackingMaterial = async (data: any) => {
    const response = await secureAPI.post("PackingMaterial", data);
    return response.data;
  };

  const addPackingMaterialsInBulk = async (data: any) => {
    const response = await secureAPI.post("PackingMaterial/bulk", data);
    return response.data;
  };

  const updatePackingMaterial = async (id: number, data: any) => {
    const response = await secureAPI.put(`PackingMaterial`, data);
    return response.data;
  };

  const archivePackingMaterial = async (id: number) => {
    const response = await secureAPI.put(`PackingMaterial/archive/${id}`);
    return response.data;
  };

  return {
    getPackingMaterials,
    getPackingMaterialById,
    addPackingMaterial,
    addPackingMaterialsInBulk,
    updatePackingMaterial,
    archivePackingMaterial,
  };
};

export default usePackingMaterialService;
