import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseRackLocationService = () => {
  const secureAPI = useSecureAPI();
  
  const addWarehouseRackLocation = async (data: any) => {
    const response = await secureAPI.post("WarehouseRackLocation", data);
    return response.data;
  };
  const addWarehouseRackLocationsInBulk = async (data: any) => {
    const response = await secureAPI.post("WarehouseRackLocation/bulk", data);
    return response.data;
  };
  const getWarehouseRackLocationsByWarehouseId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseRackLocation/forwarehouse/${id}`);
    return response.data;
  }
  const getWarehouseRackLocationById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseRackLocation/${id}`);
    return response.data;
  }

  const updateWarehouseRackLocation = async (data: any) => {
    const response = await secureAPI.put(`WarehouseRackLocation`, data);
    return response.data;
  }
  const archiveWarehouseRackLocation = async (id: number) => {
    const response = await secureAPI.put(`WarehouseRackLocation/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseRackLocation,
    addWarehouseRackLocationsInBulk,
    getWarehouseRackLocationsByWarehouseId,
    getWarehouseRackLocationById,
    updateWarehouseRackLocation,
    archiveWarehouseRackLocation
  };
};

export default useWarehouseRackLocationService;
