import { t } from "i18next";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import moment from "moment";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import {
  DATE_FORMAT,
  DateSorter,
  outboundRequestStatusColor,
} from "../../../shared/globals";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import TypeTranslator from "../../../shared/typetranslator";
import PickerModal from "../../../components/units_forms/outboundrequests/pickerlistmodal";
import {
  CancelOutlined,
  PersonRemoveOutlined,
  TransferWithinAStationOutlined,
} from "@mui/icons-material";
import ConfirmModal from "../../../components/alerts/confirmmodal";

const AssignedPickerList = (props: any) => {
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { setError, setInfo } = useNotificationContext();
  const { getOutboundRequestsByStatus, unAssignPicker, cancelOutboundRequest } =
    useOutboundRequestService();
  const {
    outboundRequestStatuses,
    getOutboundRequestStatusValue,
    getOutboundDeliveryTypeValue,
  } = TypeTranslator();
  const [dataFromApi, setDataFromApi] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "requestDate",
      headerName: `${t("Request Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "outboundDeliveryTypeId",
      headerName: `${t("Outbound Delivery Type")}`,
      flex: 1,
    },
    {
      field: "scheduleDate",
      headerName: `${t("Schedule Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "pocName",
      headerName: `${t("POC Name")}`,
      flex: 1,
    },
    {
      field: "pocPhone",
      headerName: `${t("POC Phone")}`,
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: `${t("Status")}`,
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              backgroundColor:
                outboundRequestStatusColor.light[
                  outboundRequestStatuses.find(
                    (e: any) => e?.en_value === params.value
                  )?.id
                ] || "white",
              color: `${
                outboundRequestStatusColor.regular[
                  outboundRequestStatuses.find(
                    (e: any) => e?.en_value === params.value
                  )?.id
                ]
              }`,
              padding: "5px",
              margin: "5px",
              borderRadius: "4px",
              width: 90,
              textAlign: "center",
            }}
          >
            {params.value}
          </div>
        );
      },
    },
  ];

  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    columns.splice(1, 0, customerNameColumn);
  }

  const buildActions = (params: GridRowParams) => {
    let actionsArray = [];

    actionsArray.push(
      <GridActionsCellItem
        label={t("Start Picking")}
        showInMenu
        onClick={() => {
          onEdit(params.row);
        }}
        icon={<TransferWithinAStationOutlined />}
      />
    );
    // unAssign picker
    actionsArray.push(
      <GridActionsCellItem
        label={t("Unassign Picker")}
        showInMenu
        onClick={() => {
          // handleUnassignPicker(params.row);
          setOpenConfirmModal({
            open: true,
            message: t("you want to unassign the picker?"),
            fn: () => handleUnassignPicker(params.row),
          });
        }}
        icon={<PersonRemoveOutlined />}
      />
    );

    actionsArray.push(
      <GridActionsCellItem
        label={t("Cancel Request")}
        showInMenu
        onClick={() => {
          // handleCancelRequest(params.row);
          setOpenConfirmModal({
            open: true,
            message: t("you want to cancel this request?"),
            fn: () => handleCancelRequest(params.row),
          });
        }}
        icon={<CancelOutlined />}
      />
    );

    return actionsArray;
  };
  const handleCancelRequest = (data: any) => {
    setIsLoading(true);
    cancelOutboundRequest(data.outboundRequestId)
      .then(() => {
        setInfo(t("Request canceled successfully"));
        getData();
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUnassignPicker = (data: any) => {
    setIsLoading(true);
    unAssignPicker(data.outboundRequestId)
      .then(() => {
        getData();
        setInfo(t("Picker unassigned successfully"));
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEdit = (data: any) => {
    console.log(data);
    navigate("/pickingpanel", {
      state: { requestId: data.outboundRequestId },
    });
  };

  const getData = () => {
    setIsLoading(true);
    getOutboundRequestsByStatus(5)
      .then((response: any) => {
        let updatedRows = response.map((request: any) => ({
          ...request,
          requestDate: moment(request.requestDate).format(DATE_FORMAT),
          scheduleDate: moment(request.scheduleDate).format(DATE_FORMAT),
          status: getOutboundRequestStatusValue(request.status),
          outboundDeliveryTypeId: getOutboundDeliveryTypeValue(
            request.outboundDeliveryTypeId
          ),
        }));
        // sort desc by date
        updatedRows.sort((a: any, b: any) =>
          moment(a.requestDate).isBefore(moment(b.requestDate)) ? 1 : -1
        );
        setRows(updatedRows);
        setDataFromApi(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({
        requestDate,
        outboundDeliveryTypeId,
        scheduleDate,
        status,
        pocPhone,
        pocName,
      }: any) => {
        return (
          requestDate.toLowerCase().includes(value.toLowerCase()) ||
          getOutboundDeliveryTypeValue(outboundDeliveryTypeId)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          scheduleDate.toLowerCase().includes(value.toLowerCase()) ||
          // status.toLowerCase().includes(value.toLowerCase()) ||
          pocPhone.toString().toLowerCase().includes(value.toLowerCase()) ||
          pocName.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = dataFromApi.filter(({ requestDate }: any) => {
        return moment(requestDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(dataFromApi);
  };

  useEffect(() => {
    getData();
  }, []);

  const [openConfirmModal, setOpenConfirmModal] = useState<any>({
    open: false,
    message: "",
    fn: null,
  });

  const handleConfirm = () => {
    openConfirmModal.fn();
    handleCloseConfirmModal();
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal({ open: false, message: "", fn: null });
  };
  return (
    <>
      {openConfirmModal.open && (
        <ConfirmModal
          open={openConfirmModal.open}
          onYesClick={handleConfirm}
          onNoClick={handleCloseConfirmModal}
          message={openConfirmModal.message}
        />
      )}

      <ListPageDX
        listTitle={t("Picker Assigned")}
        name={t("Picker Assigned")}
        rows={rows}
        columns={columns}
        getRowId={(row: any) => row.outboundRequestId}
        isLoading={isLoading}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={true}
        filterByDate={filterByDate}
        onEdit={onEdit}
        buildActions={buildActions}
      />
    </>
  );
};

export default AssignedPickerList;
