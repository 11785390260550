import { t } from "i18next";
import React, { useEffect, useState } from "react";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { Button, InputAdornment } from "@mui/material";
import useLeadService from "../../../shared/services/leadservice";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
  RegularExpressions,
  rentalPeriods,
  requestSources,
  storageTypes,
} from "../../../shared/globals";
import { useLocation, useNavigate } from "react-router-dom";
import NotesModal from "./leadnotesmodal";

const defaultValues = {
  requestDate: new Date().toISOString(),
  fullname: "",
  phoneNumber: "",
  email: "",
  companyName: "",
  requiredStorage: "",
  rentalPeriodMonths: null,
  storageTypeId: null,
  goodsType: "",
  // 2 because it is added manualy by admin
  requestType: 2,
  requestSource: null,
};

const LeadForm = (props: any) => {
  const { state } = useLocation();
  const leadId = state?.leadId;
  const navigate = useNavigate();

  const [data, setData] = useState<any>(defaultValues);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { getLeadById, addLead, updateLead } = useLeadService();
  const { setError, setInfo } = useNotificationContext();
  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: type == "checkbox" ? checked : value,
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (leadId) {
      setIsLoading(true);
      getLeadById(leadId)
        .then((response) => {
          setData(response);
          console.log("response", response);
          setIsFormEditable(
            response.requestType === 2 && response.requestStatus == 1
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    if (!data.fullname) {
      newErrors["fullName"] = t("Name is required");
    }
    if (!data.email) {
      newErrors["email"] = t("Email is required");
    } else if (!RegularExpressions.emailAddress.test(data.email))
      newErrors["email"] = t("Invalid email address");
    if (!data.phoneNumber) {
      newErrors["phoneNumber"] = t("Phone number is required");
    }
    // storage type
    if (!data.storageTypeId) {
      newErrors["storageTypeId"] = t("Storage type is required");
    }
    // rental period
    if (!data.rentalPeriodMonths) {
      newErrors["rentalPeriodMonths"] = t("Rental period is required");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const onSave = () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const operation = leadId ? updateLead : addLead;
    operation({ ...data, requestStatus: data.requestStatus || 1 })
      .then((response) => {
        leadId
          ? setInfo("Lead updated successfully")
          : setInfo("Lead added successfully");
        handleClose();
      })
      .catch((error) => {
        console.log("error, ", error);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    navigate(-1);
  };

  const toCreate = () => {
    navigate("/quotationdetails", {
      state: {
        quotationId: null,
        leadData: data,
      },
    });
  };

  const [showNotesModal, setShowNotesModal] = useState({
    show: false,
    modalSaveLabel: "",
    saveBtncolor: "primary",
  });

  const markAsJunk = () => {
    setShowNotesModal({
      show: true,
      modalSaveLabel: t("Mark as Junk"),
      saveBtncolor: "error",
    });
  };
  const markAsLost = () => {
    setShowNotesModal({
      show: true,
      modalSaveLabel: t("Mark as Lost"),
      saveBtncolor: "error",
    });
  };
  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Lead Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      handleGeneratePDF={props.handleGeneratePDF}
      leadRequestStatus={data.requestStatus}
      leadRequestType={data.requestType}
      markAsJunk={markAsJunk}
      markAsLost={markAsLost}
    >
      <NotesModal
        open={showNotesModal.show}
        saveLabel={showNotesModal.modalSaveLabel}
        saveBtncolor={showNotesModal.saveBtncolor}
        onClose={() =>
          setShowNotesModal({
            show: false,
            modalSaveLabel: "",
            saveBtncolor: "primary",
          })
        }
        leadData={data}
      />
      <GridDX item xs={12} md={4}>
        <TextFieldDX
          disabled={!isFormEditable}
          label={t("Name")}
          name="fullname"
          value={data?.fullname}
          onChange={handleInputChange}
          error={errors["fullName"]}
        />
      </GridDX>

      <GridDX item xs={12} md={4}>
        <TextFieldDX
          disabled={!isFormEditable}
          label={t("Phone Number")}
          name="phoneNumber"
          onChange={handleInputChange}
          value={data?.phoneNumber}
          error={errors["phoneNumber"]}
          type="number"
          // Disable spin buttons for number input
          className="noSpinButtons"
        />
      </GridDX>

      <GridDX item xs={12} md={4}>
        <TextFieldDX
          disabled={!isFormEditable}
          label={t("Email")}
          name="email"
          value={data?.email}
          onChange={handleInputChange}
          error={errors["email"]}
        />
      </GridDX>
      <GridDX item xs={12} md={4}>
        <TextFieldDX
          disabled={!isFormEditable}
          label={t("Company Name")}
          name="companyName"
          value={data?.companyName}
          onChange={handleInputChange}
          error={errors["companyName"]}
        />
      </GridDX>

      <GridDX item xs={12} md={4}>
        <TextFieldDX
          disabled={!isFormEditable}
          label={t("Required Storage")}
          name="requiredStorage"
          value={data?.requiredStorage}
          onChange={handleInputChange}
          error={errors["requiredStorage"]}
          type="number"
          InputProps={{
            inputProps: { min: 1 },
            endAdornment: <InputAdornment position="end">CBM</InputAdornment>,
          }}
        />
      </GridDX>

      <GridDX item xs={12} md={4}>
        <SelectListDX
          disabled={!isFormEditable}
          label={t("Rental Period")}
          items={rentalPeriods}
          name="rentalPeriodMonths"
          value={data.rentalPeriodMonths}
          onChange={handleInputChange}
          error={errors["rentalPeriodMonths"]}
          InputLabelProps={{
            shrink: data?.rentalPeriodMonths !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>

      <GridDX item xs={12} md={4}>
        <SelectListDX
          disabled={!isFormEditable}
          label={t("Storage Type")}
          items={storageTypes}
          name="storageTypeId"
          value={data.storageTypeId}
          onChange={handleInputChange}
          error={errors["storageTypeId"]}
          InputLabelProps={{
            shrink: data?.storageTypeId !== null, // Set to true if there's a value just to handle label position
          }}
        />
      </GridDX>
      <GridDX item xs={12} md={4}>
        <TextFieldDX
          disabled={!isFormEditable}
          label={t("Goods Type")}
          name="goodsType"
          value={data.goodsType}
          onChange={handleInputChange}
          error={errors["goodsType"]}
        />
      </GridDX>
      {data.requestType == 2 && (
        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled={!isFormEditable}
            label={t("Request Source")}
            items={requestSources}
            name="requestSource"
            value={data.requestSource}
            onChange={handleInputChange}
            error={errors["requestSource"]}
            InputLabelProps={{
              shrink: data?.requestSource !== null, // Set to true if there's a value just to handle label position
            }}
          />
        </GridDX>
      )}
      {data.requestStatus == 3 ||
        (data.requestStatus == 4 && (
          <GridDX item xs={12}>
            <TextFieldDX
              disabled
              label={t("Notes")}
              name="notes"
              value={data.notes}
              onChange={handleInputChange}
              error={errors["notes"]}
              multiline
              minRows={2}
            />
          </GridDX>
        ))}
      {data.requestStatus == 1 && (
        <GridDX item xs={12} md={12} sx={{ gap: 10 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={toCreate}
            sx={{ float: "right" }}
          >
            {t("Generate Quotation")}
          </Button>
        </GridDX>
      )}
    </AddEditEmbeddedModal>
  );
};

export default LeadForm;
