import { useEffect, useState } from "react";
import { t } from "i18next";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
  DATE_FORMAT
} from "../../../shared/globals";
import BoxDX from "../../layout/boxdx";
import { useLocation, useNavigate } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import DataGridDX from "../../layout/datagriddx";
import moment from "moment";
import ButtonDX from "../../controls/buttondx";
import TypographyDX from "../../layout/typographydx";
import ItemCountForm from "./itemcountform";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import GridSearchFilterTextbox from "../../business/gridsearchfiltertextbox";
import { Divider } from "@mui/material";

const CountingForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const requestId = state?.requestId;
  const { getInboundRequestById, updateInboundRequest, startQC } = useInboundRequestService();
  const { setInfo, setError } = useNotificationContext();

  const ItemsColumns: GridColDef[] = [
    {
      field: "skuName",
      headerName: `${t("Name")}`,
      flex: 1,
    },
    {
      field: "skuCode",
      headerName: `${t("Code")}`,
      minWidth: 150,
    },
    {
      field: "expiryDate",
      headerName: `${t("Expiry Date")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      valueFormatter(params) {
          return (params.value ? moment(params.value).format(DATE_FORMAT) : "");
      },
    },
    { field: "recievedUnits",
      headerName: `${t("Received Units")}`,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter(params) {
          return (`${params.row.quantityReceived ?? 0}/${params.row.quantity}`);
      },
    },
    {
      headerName: t("Actions"),
      field: "actions",
      align: 'center',
      headerAlign: 'center',
      renderCell(params) {
          return (
            <ButtonDX sx={{ m:1 }} onClick={()=>{
              console.log(params.row);
              setSelectedSKU(params.row);
              setOpenCountForm(true);
            }}>
              {t("Count")}
            </ButtonDX>
          );
      },
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    }
  ];
  
  const [requestData, setRequestData] = useState<any>();
  const [selectedSKU, setSelectedSKU] = useState<any>(null);
  const [rows, setRows] = useState<any>([]);
  const [skus, setSKUs] = useState<any>([]);
  const [counted, setCounted] = useState<any>(0);
  const [isChanged, setIsChanged] = useState(false);
  const [openCountForm, setOpenCountForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    navigate("/inboundrequests");
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);

    if (name === "quantityReceived") 
      setSelectedSKU({
        ...selectedSKU,
        quantityReceived: value,
        missingUnits: parseInt(selectedSKU.quantity) - parseInt(value)
      });
    else {
      setSelectedSKU({
        ...selectedSKU,
        [name]: value
      });
    }
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setSelectedSKU({
      ...selectedSKU,
      [name]: moment(value).format(DATE_FORMAT),
    });
  };

  const onSave = (status: any) => {
    let data = {
      ...requestData,
      inboundRequestSkus: skus
    };
    
    if (status === 2) {
      data = {
        ...data,
        status: 8,
      };
      updateInboundRequest(data)
        .then((res) => {
          setInfo(t("Moved to QC successfully"));
          navigate("/qcpanel", {
            state: { requestId: data.inboundRequestId},
          });
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    else {
      updateRequest(data);
    }
  };

  const updateRequest = async (data: any) => {
    setIsLoading(true);
    updateInboundRequest(data)
      .then((res) => {
        setInfo(t("Request updated successfully"));
        setIsChanged(false);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const onClose = (confirmCount:boolean = false) => {
    let data = selectedSKU;
    const index = skus.findIndex((sku: any) => sku.inboundRequestSkuId === selectedSKU.inboundRequestSkuId);
  
    if(confirmCount && !selectedSKU.counted) {
      setCounted(counted+1);
      if (!skus[index].counted) data = {
        ...selectedSKU,
        counted: true
      };
    }
    
    setSKUs(
      [
        ...skus.slice(0, index),
        { ...data },
        ...skus.slice(index + 1),
      ]
    );
    setRows(
      [
        ...skus.slice(0, index),
        { ...data },
        ...skus.slice(index + 1),
      ]
    );
    setSelectedSKU(null);
    setOpenCountForm(false);
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(skus);
      return;
    }
    const newRows = skus.filter(
      (row: any) =>
        row.skuName.toLowerCase().includes(value.toLowerCase()) ||
        row.skuCode.toLowerCase().includes(value.toLowerCase())
    );
    setRows(newRows);
  };

  const getData = () => {
    setIsLoading(true);
    getInboundRequestById(requestId)
      .then((res) => {
        let count = 0;
        const skuList = res.inboundRequestSkus.map((item: any) => {
          const received = item.quantityReceived;
          if (received) count += 1;
          return {
            ...item,
            expiryDate: item.expiryDate ? moment(item.expiryDate).format(DATE_FORMAT) : null,
            counted: received ? true : false
          }
        });
        setRequestData(res);
        setCounted(count);
        setSKUs(skuList);
        setRows(skuList);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (requestId) getData();
  }, []);

  return (
    <BoxDX sx={{ width: "100%" }}>
      <AddEditEmbeddedModal
        handleClose={handleClose}
        isSaving={isLoading}
        isChanged={isChanged}
        title={t("Counting Panel")}
        onSaveClick={onSave}
        showSaveAndSubmit={counted === skus.length}
        saveAndSubmitLabel={t("Move to QC")}
        onYesClick={handleClose}
        isPrintable={false}
      >
        {openCountForm && <ItemCountForm data={selectedSKU} onChange={handleInputChange} onDateChange={handleDateChange} handleClose={onClose}/>}
        <GridDX
          container
          alignItems="center"
          columnSpacing={1}
          rowSpacing={2}
        >
          <GridDX item xs={6} justifyContent="space-between">
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t("GRN No.")}`}</TypographyDX>
              <TypographyDX>{requestData?.grnNumber}</TypographyDX>
            </BoxDX>
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t("Request No.")}`}</TypographyDX>
              <TypographyDX>{requestData?.inboundRequestId}</TypographyDX>
            </BoxDX>
            <BoxDX>
              <TypographyDX fontWeight={"bold"}>{`${t("Total SKUs")}`}</TypographyDX>
              <TypographyDX>{skus.length}</TypographyDX>
            </BoxDX>
          </GridDX>
          <Divider sx={{ my: 1, width: "100%" }}/>
          <GridDX item xs={12}>
            <TypographyDX fontWeight={"bold"}>{`${t("Counting InProgress")} :`}</TypographyDX>
            <TypographyDX mx={2}>{`${counted}/${skus.length} ${t("SKUs")}`}</TypographyDX>
          </GridDX>
          <GridDX item xs={4}>
            <GridSearchFilterTextbox
              placeholder={t("Search")}
              sx={{
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px 0px #0000000F",
                border: "1px solid #D0D5DD",
              }}
              setGridFilterCriteria={setGridFilterCriteria}
            />
          </GridDX>
          <GridDX item xs={12}>
            <DataGridDX
              getRowId={(row: any) => row.inboundRequestSkuId}
              rows={rows}
              columns={ItemsColumns}
              loading={isLoading}
              disableRowSelectionOnClick={true}
              checkboxSelection={false}
            />
          </GridDX>
        </GridDX>
      </AddEditEmbeddedModal>
    </BoxDX>
  );
};

export default CountingForm;
