import { t } from "i18next";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../../../context/notificationcontext";
import { DocumentScannerOutlined } from "@mui/icons-material";
import ListPageDX from "../../../components/business/listpagedx";
import moment from "moment";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";
import { DATE_FORMAT } from "../../../shared/globals";
import { useAuthContext } from "../../../context/authcontext";
import GRNReport from "../../../components/pages/reports/grn_report";

const CompletedList = (props: any) => {
  const { userData } = useAuthContext();
  const { setError } = useNotificationContext();
  const { getInboundRequestsByStatus} = useInboundRequestService();

  const [requests, setRequests] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const CompletedColumns: GridColDef[] = [
    {
      field: "grnNumber",
      headerName: `${t("GRN No.")}`,
      flex: 1,
    },
    {
      field: "inboundRequestId",
      headerName: `${t("Request No.")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "recievingDate",
      headerName: `${t("Receiving Date")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      valueFormatter(params) {
          return moment(params.value).format(DATE_FORMAT);
      },
    },
    {
      headerName: t("Actions"),
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => {
        let actionsArray = [];

        actionsArray.push(
          <GridActionsCellItem
            label={t("Download Document")}
            showInMenu
            onClick={() => {
              setSelectedRequest(params.row);
              setShowPreview(true);
            }}
            icon={<DocumentScannerOutlined />}
          />
        );

        return actionsArray;
      },

      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    CompletedColumns.splice(1, 0, customerNameColumn);
  }

  const getData = () => {
    setIsLoading(true);
    getInboundRequestsByStatus(10)
      .then((response: any) => {
        let updatedRows = response.map((request: any) => ({
          ...request,
          requestDate: moment(request.recievingDate).format(DATE_FORMAT),
        }));
        // sort desc by date
        updatedRows.sort((a: any, b: any) =>
          moment(a.recievingDate).isBefore(moment(b.recievingDate)) ? 1 : -1
        );
      
        setRows(updatedRows);
        setRequests(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(requests);
      return;
    }
    const newRows = requests.filter(
      ({
        grnNumber,
        recievingDate,
      }: any) => {
        return (
          grnNumber.toLowerCase().includes(value.toLowerCase()) ||
          recievingDate.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = requests.filter(({ createdDate }: any) => {
        return moment(createdDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(requests);
  };
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {showPreview && (
        <GRNReport
          open={showPreview}
          onClose={() => setShowPreview(false)}
          requestData={selectedRequest}
        />
      )}
      <ListPageDX
        listTitle={t("Completed Requests")}
        name={t("Completed Requests")}
        rows={rows}
        columns={CompletedColumns}
        getRowId={(row: any) => row.inboundRequestId}
        isLoading={isLoading}
        setGridFilterCriteria={setGridFilterCriteria}
        exportToPDF={false}
        exportToCSV={false}
        showDatePicker={true}
        filterByDate={filterByDate}
      />
    </>
  );
};

export default CompletedList;