import { useEffect, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import AddEditModalDX from "../../business/addeditmodaldx";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";
import TextFieldDX from "../../controls/textfielddx";

const OnHoldModal = (props: any) => {
  const { open, handleClose, outboundRequestId } = props;
  const {  moveToOnHold } = useOutboundRequestService();
  const [onHoldReason, setOnHoldReason] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const { setError, setInfo } = useNotificationContext();
  const [errors, setErrors] = useState<any>({});

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true)
      moveToOnHold({ outboundRequestId, onHoldReason })
        .then((response: any) => {
          setInfo(t("Request moved to On Hold successfully"));
          handleClose();
        })
        .catch((error: any) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        }
        );
     
    }
  };

  const validateForm = () => {
    const newErrors: any = {};
    
    if (!onHoldReason) {
      newErrors.onHoldReason = t("Please enter holding reason");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

 
 
 

  return (
    <AddEditModalDX
      open={open}
      title={t("Hold Request")}
      handleClose={handleClose}
      maxWidth="sm"
      isSaving={isLoading}
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      <GridDX container rowSpacing={3} py={1} columnSpacing={1}>
        <GridDX item xs={12}>
         <TextFieldDX
          label={t("On Hold Reason")}
          name="onHoldReason"
          value={onHoldReason}
          onChange={(e: any) => setOnHoldReason(e.target.value)}
          error={errors["onHoldReason"]}
          multiline
          minRows={2}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default OnHoldModal;
