import { t } from "i18next";
import { useState, useEffect } from "react";
import { useOutletContext, useLocation } from "react-router-dom";
import { useNotificationContext } from "../../../../context/notificationcontext";
import useWarehouseAreaService from "../../../../shared/services/warehouseareaservice";
import TypeTranslator from "../../../../shared/typetranslator";
import SelectListDX from "../../../controls/selectlistdx";
import TextFieldDX from "../../../controls/textfielddx";
import BoxDX from "../../../layout/boxdx";
import GridDX from "../../../layout/griddx";
import ItemBox from "../../../units_components/itembox";

const WarehouseAreaDetails = (props: any) => {
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;

  const { state } = useLocation();
  const warehouseId = state?.warehouseId;
  const warehouseAreaId = state?.warehouseAreaId;
  const { setInfo, setError } = useNotificationContext();
  const { addWarehouseArea, updateWarehouseArea, getWarehouseAreaById } =
    useWarehouseAreaService();
  const { warehouseCategories } = TypeTranslator();

  const defaultValues = {
    warehouseId: warehouseId,
    warehouseAreaId: 0,
    name: "",
    code: "",
    warehouseCategoryTypeId: null,
    heightInCm: 0,
    widthInCm: 0,
    lengthInCm: 0,
  };

  const [areaData, setareaData] = useState<any>(defaultValues);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [areaData]);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setareaData({
      ...areaData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!areaData.name) {
      newErrors["name"] = t("Name is required");
    }
    if (!areaData.code) {
      newErrors["code"] = t("Code is required");
    }
    if (!areaData.warehouseCategoryTypeId) {
      newErrors["warehouseCategoryTypeId"] = t("Category is required");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (!isDataChanged) {
      handleClose();
      return;
    }
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);
      const operation = warehouseAreaId
        ? updateWarehouseArea(areaData)
        : addWarehouseArea(areaData);
      operation
        .then(() => {
          warehouseAreaId
            ? setInfo(t("Warehouse Area updated successfully"))
            : setInfo(t("Warehouse Area added successfully"));
          handleClose();
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };
  
  const getData = () => {
    if (warehouseAreaId) {
      setIsLoading(true);
      getWarehouseAreaById(warehouseAreaId)
        .then((area) => {
          setareaData(area);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Name")}
              name="name"
              value={areaData?.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Code")}
              name="code"
              value={areaData?.code}
              onChange={handleInputChange}
              error={errors["code"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Category")}
              name="warehouseCategoryTypeId"
              value={areaData?.warehouseCategoryTypeId}
              items={warehouseCategories}
              onChange={handleInputChange}
              error={errors["warehouseCategoryTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: areaData?.storageModelTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Height")} (${t("cm")})`}
            name="heightInCm"
            value={areaData?.heightInCm}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Width")} (${t("cm")})`}
            name="widthInCm"
            value={areaData?.widthInCm}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Length")} (${t("cm")})`}
            name="lengthInCm"
            value={areaData?.lengthInCm}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        </GridDX>
      </ItemBox>
    </BoxDX>
  );
};

export default WarehouseAreaDetails;
