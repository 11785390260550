import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseTimeslotService = () => {
  const secureAPI = useSecureAPI();

  
  const addWarehouseTimeslot = async (data: any) => {
    const response = await secureAPI.post("WarehouseTimeslot", data);
    return response.data;
  };
  const getWarehouseTimeslotsByWarehouseId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseTimeslot/forwarehouse/${id}`);
    return response.data;
  }
  const getWarehouseTimeslotById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseTimeslot/${id}`);
    return response.data;
  }

  const updateWarehouseTimeslot = async (data: any) => {
    const response = await secureAPI.put(`WarehouseTimeslot`, data);
    return response.data;
  }
  const archiveWarehouseTimeslot = async (id: number) => {
    const response = await secureAPI.put(`WarehouseTimeslot/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseTimeslot,
    getWarehouseTimeslotsByWarehouseId,
    getWarehouseTimeslotById,
    updateWarehouseTimeslot,
    archiveWarehouseTimeslot
  };
};

export default useWarehouseTimeslotService;
