import { t } from "i18next";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import useBrandService from "../../../shared/services/brandservice";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";


const BrandForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const packageData = state?.packageData;
  const handleClose = () => {
    navigate(-1);
  };
  const { setInfo, setError } = useNotificationContext();
  const { addBrand, updateBrand } = useBrandService();

  const defaultData = {
    name: "",
  };

  const [data, setData] = useState<any>(defaultData);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name) newErrors["name"] = "Name is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsLoading(true);

      const operation = packageData
        ? updateBrand(data.BrandId, data)
        : addBrand(data);
      operation
        .then(() => {
          setInfo(
            packageData
              ? t("Brand updated successfully")
              : t("Brand added successfully")
          );
          handleClose();
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (packageData) setData(packageData);
  }, []);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Brand Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      <GridDX
        container
        sx={{
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default BrandForm;
