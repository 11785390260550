import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseOrderBinService = () => {
  const secureAPI = useSecureAPI();
  
  const addWarehouseOrderBin = async (data: any) => {
    const response = await secureAPI.post("WarehouseOrderBin", data);
    return response.data;
  };
  const addWarehouseOrderBinInBulk = async (data: any) => {
    const response = await secureAPI.post("WarehouseOrderBin/bulk", data);
    return response.data;
  };
  const getWarehouseOrderBinsByWarehouseId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseOrderBin/forwarehouse/${id}`);
    return response.data;
  }
  const getWarehouseOrderBinById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseOrderBin/${id}`);
    return response.data;
  }

  const updateWarehouseOrderBin = async (data: any) => {
    const response = await secureAPI.put(`WarehouseOrderBin`, data);
    return response.data;
  }
  const archiveWarehouseOrderBin = async (id: number) => {
    const response = await secureAPI.put(`WarehouseOrderBin/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseOrderBin,
    addWarehouseOrderBinInBulk,
    getWarehouseOrderBinsByWarehouseId,
    getWarehouseOrderBinById,
    updateWarehouseOrderBin,
    archiveWarehouseOrderBin
  };
};

export default useWarehouseOrderBinService;
