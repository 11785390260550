import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ListPageDX from "../business/listpagedx";
import ListPageTemplate from "../../templates/listpagetemplate";
import GridDX from "../layout/griddx";
import ButtonDX from "../controls/buttondx";
import TypographyDX from "../layout/typographydx";
import QuotationItemForm from "../units_forms/quotations/quotationitemform";
import { AddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNotificationContext } from "../../context/notificationcontext";
import { getTotalAmount, numberWithCommas } from "../../shared/globals";

const QuotationsItems = (props: any) => {
  const {
    quotationData,
    setQuotationData,
    getData,
    setIsChanged,
    isFormEditable,
    subscriptionPackages,
  } = props;
  const { setInfo } = useNotificationContext();
  const [quotationItemId, setQuotationItemId] = useState(null);
  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const columns: GridColDef[] = [
    { field: "itemName", headerName: t("Item Name"), flex: 1 },
    {
      field: "description",
      headerName: t("Description"),
      // show limited text in the description column
      valueFormatter: ({ value }) => {
        return value.length > 50 ? value.substring(0, 50) + "..." : value;
      },
    },
    {
      field: "unitPrice",
      headerName: t("Unit Price"),
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      valueFormatter: ({ value }) => numberWithCommas(value),
      align: "right",
    },
    {
      field: "quantity",
      headerName: t("Quantity"),
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      align: "right",
      headerAlign: "right",
    },
    {
      field: "discountPercent",
      headerName: t("Discount (%)"),
      flex: 1,
      align: "right",
      // it has to be a number to be able to sort
      type: "number",
    },
    {
      field: "discountAmount",
      headerName: t("Discount Amount"),
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      valueFormatter: ({ value }) => numberWithCommas(value),
      align: "right",
    },
    {
      field: "totalAmount",
      headerName: t("Total Amount"),
      flex: 1,
      // it has to be a number to be able to sort
      type: "number",
      valueFormatter: ({ value }) => numberWithCommas(value),
      align: "right",
      headerAlign: "right",
      
    },
  ];

  const onDelete = (id: number) => {
    setQuotationData((prev: any) => {
      const deletedItem = prev.quotationItems.find(
        (item: any) => item.item_id === id
      );
      const updatedItems = prev.quotationItems.filter(
        (item: any) => item.item_id !== id
      );
      const updatedData = {
        ...prev,
        quotationItems: [...updatedItems, { ...deletedItem, isDeleted: true }],
        ...getTotalAmount({ ...prev, items: updatedItems }),
      };
      return updatedData;
    });
    setIsChanged(true);
    setInfo("Item deleted successfully");
  };

  const [showForm, setShowForm] = useState(false);
  const onEdit = (data: any) => {
    setQuotationItemId(data.item_id);
    setShowForm(true);
  };

  const toCreate = () => {
    setShowForm(true);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowForm(false);
    setQuotationItemId(null);

    if (refreshPage) getData();
  };
  const [items, setItems] = useState([]);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const filteredItems = quotationData.quotationItems.filter(
      (item: any) => !item.isDeleted
    );
    setItems(filteredItems);
    setRows(filteredItems);
  }, [quotationData.quotationItems]);

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(items);
      return;
    }
    const newRows = items.filter(
      ({
        description,
        itemName,
        unitPrice,
        quantity,
        discountPercent,
        discountAmount,
        totalAmount,
      }: any) => {
        return (
          description.toLowerCase().includes(value.toLowerCase()) ||
          itemName.toLowerCase().includes(value.toLowerCase()) ||
          unitPrice.toString().toLowerCase().includes(value.toLowerCase()) ||
          quantity.toString().toLowerCase().includes(value.toLowerCase()) ||
          discountPercent
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          discountAmount
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          totalAmount.toString().toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };
  return (
    <>
      {showForm && (
        <QuotationItemForm
          quotationData={quotationData}
          quotationItemId={quotationItemId}
          setQuotationData={setQuotationData}
          handleClose={onClose}
          setIsChanged={setIsChanged}
          subscriptionPackages={subscriptionPackages}
        />
      )}
      <GridDX container xs={12}>
        <GridDX item xs={12} justifyContent="space-between">
          <TypographyDX variant="h5">{t("Quotation Items")}</TypographyDX>
          {isFormEditable && (
            <Button
              variant="contained"
              color="secondary"
              sx={{ color: "#fff", wordSpacing: 4 }}
              onClick={toCreate}
            >
              <AddOutlined /> {t("Add Item")}
            </Button>
          )}
        </GridDX>
        <GridDX item xs={12} sx={{ mb: 2 }}>
          <ListPageDX
            listTitle={t("Quotation Item")}
            name={t("Quotation Item")}
            rows={rows}
            columns={columns}
            getRowId={(row: any) => row.item_id}
            isLoading={isLoading}
            // toCreate={toCreate}
            onEdit={isFormEditable && onEdit}
            editLabel={t("Edit")}
            onDelete={isFormEditable && onDelete}
            exportToPDF={false}
            exportToCSV={false}
            showDatePicker={false}
            showSelect={false}
            searchAndFilter={true}
            exportDropdown={false}
            setGridFilterCriteria={setGridFilterCriteria}
          />
        </GridDX>
      </GridDX>
    </>
  );
};

export default QuotationsItems;
