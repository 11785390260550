import { t } from "i18next";
import { useState, useEffect } from "react";
import { useOutletContext, useLocation } from "react-router-dom";
import { useNotificationContext } from "../../../../context/notificationcontext";
import useWarehouseAreaService from "../../../../shared/services/warehouseareaservice";
import useWarehouseRackService from "../../../../shared/services/warehouserackservice";
import TypeTranslator from "../../../../shared/typetranslator";
import SelectListDX from "../../../controls/selectlistdx";
import TextFieldDX from "../../../controls/textfielddx";
import BoxDX from "../../../layout/boxdx";
import GridDX from "../../../layout/griddx";
import ItemBox from "../../../units_components/itembox";

const WarehouseRackDetails = (props: any) => {
  const {
    setIsDataChanged,
    setSaveRecordfn,
    handleClose,
    isDataChanged,
    setIsSaving,
  } = useOutletContext() as any;

  const { state } = useLocation();
  const warehouseId = state?.warehouseId;
  const warehouseRackId = state?.warehouseRackId;
  const { setInfo, setError } = useNotificationContext();
  const { getWarehouseAreasByWarehouseId } = useWarehouseAreaService();
  const { addWarehouseRack, updateWarehouseRack, getWarehouseRackById } =
    useWarehouseRackService();
  const { warehouseCategories, rackSizes } = TypeTranslator();

  const defaultValues = {
    warehouseAreaId: null,
    warehouseRackId: 0,
    name: "",
    code: "",
    warehouseCategoryTypeId: null,
    rackSizeTypeId: null,
    heightInCm: 0,
    widthInCm: 0,
    lengthInCm: 0,
  };

  const [RackData, setRackData] = useState<any>(defaultValues);
  const [areaList, setAreaList] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFormEditable, setIsFormEditable] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setSaveRecordfn(() => onSave);
  }, [RackData]);

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsDataChanged(true);
    setRackData({
      ...RackData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!RackData.name) {
      newErrors["name"] = t("Name is required");
    }
    if (!RackData.code) {
      newErrors["code"] = t("Code is required");
    }
    if (!RackData.warehouseCategoryTypeId) {
      newErrors["warehouseCategoryTypeId"] = t("Category is required");
    }
    if (!RackData.rackSizeTypeId) {
      newErrors["rackSizeTypeId"] = t("Size is required");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (!isDataChanged) {
      handleClose();
      return;
    }
    if (validateForm()) {
      setIsSaving(true);
      setIsFormEditable(false);
      const operation = warehouseRackId
        ? updateWarehouseRack(RackData)
        : addWarehouseRack(RackData);
      operation
        .then(() => {
          warehouseRackId
            ? setInfo(t("Warehouse Rack updated successfully"))
            : setInfo(t("Warehouse Rack added successfully"));
          handleClose();
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsSaving(false);
          setIsFormEditable(true);
        });
    }
  };

  const getAreas = async () => {
    setIsLoading(true);
    getWarehouseAreasByWarehouseId(warehouseId)
      .then((areas) => {
        setAreaList(areas.map((area: any) => {
          return {
            text: area.name,
            value: area.warehouseAreaId
          };
        }));
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  
  const getData = async () => {
    if (warehouseRackId) {
      setIsLoading(true);
      getWarehouseRackById(warehouseRackId)
        .then((Rack) => {
          setRackData(Rack);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
    getAreas();
  };

  return (
    <BoxDX sx={{ flexDirection: "column", width: "100%" }}>
      <ItemBox>
        <GridDX container columnSpacing={1} rowSpacing={2}>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Name")}
              name="name"
              value={RackData?.name}
              onChange={handleInputChange}
              error={errors["name"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <TextFieldDX
              disabled={!isFormEditable}
              label={t("Code")}
              name="code"
              value={RackData?.code}
              onChange={handleInputChange}
              error={errors["code"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Area")}
              name="warehouseAreaId"
              value={RackData?.warehouseAreaId}
              items={areaList}
              onChange={handleInputChange}
              error={errors["warehouseAreaId"]}
              //shrink
              InputLabelProps={{
                shrink: RackData?.storageModelTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Category")}
              name="warehouseCategoryTypeId"
              value={RackData?.warehouseCategoryTypeId}
              items={warehouseCategories}
              onChange={handleInputChange}
              error={errors["warehouseCategoryTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: RackData?.storageModelTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
            <SelectListDX
              disabled={!isFormEditable}
              label={t("Size")}
              name="rackSizeTypeId"
              value={RackData?.rackSizeTypeId}
              items={rackSizes}
              onChange={handleInputChange}
              error={errors["rackSizeTypeId"]}
              //shrink
              InputLabelProps={{
                shrink: RackData?.storageModelTypeId !== null, // Set to true if there's a value just to handle label position
              }}
            />
          </GridDX>
          <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Height")} (${t("cm")})`}
            name="heightInCm"
            value={RackData?.heightInCm}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Width")} (${t("cm")})`}
            name="widthInCm"
            value={RackData?.widthInCm}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={`${t("Length")} (${t("cm")})`}
            name="lengthInCm"
            value={RackData?.lengthInCm}
            onChange={handleInputChange}
            type="number"
          />
        </GridDX>
        </GridDX>
      </ItemBox>
    </BoxDX>
  );
};

export default WarehouseRackDetails;
