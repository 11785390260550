import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNotificationContext } from "../context/notificationcontext";
import ListPageDX from "../components/business/listpagedx";
import { useOutletContext } from "react-router-dom";
import { DateSorter, generateRandomDate, getTotalAmount } from "../shared/globals";
import PutawayRequestForm from "../components/units_forms/putawayrequests/putawayrequestform";
import usePutawayService from "../shared/services/putawayrequestservice";

const PutawayRequests = () => {
  const { setShowTitle, setShowAddButton, setAddRecordfn } =
    useOutletContext() as any;
  const { t, i18n } = useTranslation();
  const { setInfo, setError } = useNotificationContext();
  const [rows, setRows] = useState<any>([
    {
      movement_request_id: 1,
      request_date: generateRandomDate(),
      requested_by_user_id: "user123",
      from_warehouse_id: "Warehouse 1",
      to_warehouse_id: "Warehouse 2",
      current_status: "Pending",
      items: [],
    },
    {
      movement_request_id: 2,
      request_date: generateRandomDate(),
      requested_by_user_id: "user456",
      from_warehouse_id: "Warehouse 2",
      to_warehouse_id: "Warehouse 1",
      current_status: "Approved",
      items: [],
    },
  ]);

  const [dataFromApi, setDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [movementRequestId, setMovementRequestId] = useState(null);

  const columns: GridColDef[] = [
    {
      field: "request_date",
      headerName: `${t("Request Date")}`,
      flex: 1,
      sortComparator: DateSorter,
    },
    {
      field: "requested_by_user_id",
      headerName: `${t("Requested By User")}`,
      flex: 1,
    },
    {
      field: "from_warehouse_id",
      headerName: `${t("From Warehouse")}`,
      flex: 1,
    },
    {
      field: "to_warehouse_id",
      headerName: `${t("To Warehouse")}`,
      flex: 1,
    },
    {
      field: "current_status",
      headerName: `${t("Current Status")}`,
      flex: 1,
    },
  ];

  const getData = () => {
    const updatedRows = rows.map((row: any) => ({
      ...row,
      ...getTotalAmount(row, row.items),
    }));
    //@ts-ignore
    setRows(updatedRows);
    setDataFromApi(updatedRows);

    // setIsLoading(true);
    // getMovementRequests()
    //   .then((response: any) => {
    //     setRows(response.data);
    //     setDataFromApi(response.data);
    //   })
    //   .catch((error: any) => {
    //     setError(error.message);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //   });
  };

  const onDelete = (id: number) => {
    setRows(rows.filter((row: any) => row.movement_request_id !== id));
    setInfo(t("Putaway request deleted successfully"));
    //setIsLoading(true);
    // deleteMovementRequest(id)
    // .then((response: any) => {
    //   getData();
    // })
    // .catch((error: any) => {
    //   setError(error.message);
    // })
    // .finally(() => {
    //   setIsLoading(false);
    // });
  };
  const onEdit = (data: any) => {
    setMovementRequestId(data.movement_request_id);
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const toCreate = () => {
    setShowForm(true);
    setShowTitle(false);
    setShowAddButton(false);
  };

  const onClose = (refreshPage: boolean = false) => {
    setShowTitle(true);
    setShowForm(false);
    setMovementRequestId(null);
    setShowAddButton(true);

    if (refreshPage) getData();
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(dataFromApi);
      return;
    }
    const newRows = dataFromApi.filter(
      ({
        request_date,
        requested_by_user_id,
        from_warehouse_id,
        to_warehouse_id,
        current_status,
      }: any) => {
        return (
          request_date.toLowerCase().includes(value.toLowerCase()) ||
          requested_by_user_id.toLowerCase().includes(value.toLowerCase()) ||
          from_warehouse_id.toLowerCase().includes(value.toLowerCase()) ||
          to_warehouse_id.toLowerCase().includes(value.toLowerCase()) ||
          current_status.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  useEffect(() => {
    setAddRecordfn(() => toCreate);
    getData();
    return () => {
      setShowTitle(true);
      setAddRecordfn(() => {});
    };
    //  whenever items change, recalculate the total amount
  }, [i18n.language]);

  return (
    <>
      {showForm ? (
        <PutawayRequestForm
          requestId={movementRequestId}
          rows={rows}
          setRows={setRows}
          handleClose={onClose}
        />
      ) : (
        <ListPageDX
          listTitle={t("Putaway Requests")}
          name={t("Putaway Request")}
          rows={rows}
          columns={columns}
          getRowId={(row: any) => row.movement_request_id}
          isLoading={isLoading}
          // toCreate={toCreate}
          onEdit={onEdit}
          editLabel={t("Edit")}
          onDelete={onDelete}
          setGridFilterCriteria={setGridFilterCriteria}
          exportToPDF={false}
          exportToCSV={false}
          showDatePicker={true}
        />
      )}
    </>
  );
};

export default PutawayRequests;
