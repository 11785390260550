import { t } from "i18next";
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import { Check } from "@mui/icons-material";
import moment from "moment";
import ListPageDX from "../../../components/business/listpagedx";
import { useAuthContext } from "../../../context/authcontext";
import { DATE_FORMAT } from "../../../shared/globals";
import useInboundRequestService from "../../../shared/services/inboundrequestservice";

const QCList = (props: any) => {
  const navigate = useNavigate();
  const { userData } = useAuthContext();
  const { setError } = useNotificationContext();
  const { getInboundRequestsByStatus } = useInboundRequestService();

  const [QCs, setQCs] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const QCColumns: GridColDef[] = [
    {
      field: "grnNumber",
      headerName: `${t("GRN No.")}`,
      flex: 1,
    },
    {
      field: "inboundRequestId",
      headerName: `${t("Request No.")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: "recievingDate",
      headerName: `${t("Receiving Date")}`,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      valueFormatter(params) {
          return (params.value ? moment(params.value).format(DATE_FORMAT) : "");
      },
    },
  ];

  if (userData.userType === "S") {
    const customerNameColumn = {
      field: "customerName",
      headerName: `${t("Customer Name")}`,
      flex: 1,
    };
    QCColumns.splice(1, 0, customerNameColumn);
  }

  const buildActions = (params: GridRowParams) => {
    let actionsArray = [];

    actionsArray.push(
      <GridActionsCellItem
        label={t("Check")}
        showInMenu
        onClick={() => {
          onEdit(params.row);
        }}
        icon={<Check />}
      />
    );

    return actionsArray;
  };

  const onEdit = (data: any) => {
    navigate("/qcpanel", {
      state: { requestId: data.inboundRequestId},
    });
  };

  const getData = () => {
    setIsLoading(true);
    getInboundRequestsByStatus(8)
      .then((response: any) => {
        let updatedRows = response.map((request: any) => ({
          ...request,
          recievingDate: moment(request.recievingDate).format(DATE_FORMAT),
        }));
        // sort desc by date
        updatedRows.sort((a: any, b: any) =>
          moment(a.recievingDate).isBefore(moment(b.recievingDate)) ? 1 : -1
        );
        
        setRows(updatedRows);
        setQCs(updatedRows);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setGridFilterCriteria = (value: string) => {
    if (!value.trim()) {
      setRows(QCs);
      return;
    }
    const newRows = QCs.filter(
      ({
        grnNumber,
        recievingDate,
      }: any) => {
        return (
          grnNumber.toLowerCase().includes(value.toLowerCase()) ||
          recievingDate.toLowerCase().includes(value.toLowerCase())
        );
      }
    );
    setRows(newRows);
  };

  const filterByDate = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const sd = moment(startDate);
      const ed = moment(endDate);
      const newRows = QCs.filter(({ recievingDate }: any) => {
        return moment(recievingDate).isBetween(sd, ed, undefined, "[]");
      });
      setRows(newRows);
    } else setRows(QCs);
  };
  
  useEffect(() => {
    getData();
  }, []);

  return (
    <ListPageDX
      listTitle={t("QC")}
      name={t("QC")}
      rows={rows}
      columns={QCColumns}
      getRowId={(row: any) => row.inboundRequestId}
      isLoading={isLoading}
      setGridFilterCriteria={setGridFilterCriteria}
      exportToPDF={false}
      exportToCSV={false}
      showDatePicker={true}
      filterByDate={filterByDate}
      onEdit={onEdit}
      buildActions={buildActions}
    />
  );
};

export default QCList;