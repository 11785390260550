import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import CheckBoxDX from "../../controls/checkboxdx";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";
import { useNotificationContext } from "../../../context/notificationcontext";
import {
  DATE_FORMAT,
  generateRandomDate,
  getTotalAmount,
} from "../../../shared/globals";
import UnitsDatePicker from "../../units_controls/units_datepicker";
import moment from "moment";
import PutawayRequestItems from "../../units_components/putawayrequestitems";
import BoxDX from "../../layout/boxdx";
import TypographyDX from "../../layout/typographydx";
import usePutawayRequestService from "../../../shared/services/putawayrequestservice";

const defaultValues = {
  movement_request_id: Math.floor(Math.random() * 1000),
  request_date: generateRandomDate(),
  requested_by_user_id: "user123",
  from_warehouse_id: "",
  to_warehouse_id: "",
  current_status: "Pending",
  gross_amount: 0,
  vat_percent: 0,
  vat_amount: 0,
  net_amount: 0,
  items: [],
};

const PutawayRequestForm = (props: any) => {
  const { rows, setRows, requestId, handleClose } = props;
  const {} = usePutawayRequestService();
  // Refs for input fields
  const fromWareHouseRef = useRef<HTMLInputElement>(null);
  const toWareHouseRef = useRef<HTMLInputElement>(null);

  const [requestData, setRequestData] = useState<any>(defaultValues);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const { setInfo } = useNotificationContext();

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setRequestData({
      ...requestData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleDateChange = (value: any, name: any) => {
    setIsChanged(true);
    setRequestData({
      ...requestData,
      [name]: moment(value).format(DATE_FORMAT),
    });
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!requestData.from_warehouse_id) {
      newErrors["from_warehouse_id"] = t("From Warehouse is required");
    }
    if (!requestData.to_warehouse_id) {
      newErrors["to_warehouse_id"] = t("To Warehouse is required");
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      if (newErrors["from_warehouse_id"]) fromWareHouseRef.current?.focus();
      if (newErrors["to_warehouse_id"]) toWareHouseRef.current?.focus();
      // Focus on more fields if needed

      return false; // Form is not valid
    }

    return Object.keys(newErrors).length === 0;
  };
  const onSave = () => {
    setIsLoading(true);
    if (validateForm()) {
      const newRows = [...rows];
      const index = newRows.findIndex(
        (row) => row.movement_request_id === requestData.movement_request_id
      );
      if (index > -1) {
        newRows[index] = requestData;
        setInfo(t("Request updated successfully"));
      } else {
        newRows.push({
          ...requestData,
          movement_request_id: Math.floor(Math.random() * 1000),
        });
        setInfo(t("Request added successfully"));
      }
      setRows(() => newRows);
      setIsChanged(false);
      handleClose();

      // setIsLoading(true);
      // const operation = requestId ? updateMovementRequest : addMovementRequest;
      // operation(requestData)
      //   .then((response: any) => {
      // requestId ? setInfo(t("Request updated successfully")) : setInfo(t("Request added successfully"));
      // handleClose();
      // })
      // .catch((error: any) => {
      //   setError(error.message);
      // })
      // .finally(() => {
      //   setIsLoading(false);
      // });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (requestId) {
      setRequestData(
        rows.find((row: any) => row.movement_request_id === requestId)
      );
      // setIsLoading(true);
      // getMovementRequestById(requestId)
      //   .then((response: any) => {
      //     setRequestData(response.data);
      //   })
      //   .catch((error: any) => {
      //     setError(error.message);
      //   })
      //   .finally(() => {
      //     setIsLoading(false);
      //   });
    }
  };

  useEffect(() => {
    setRequestData((prevData: any) => ({
      ...prevData,
      ...getTotalAmount(prevData, prevData.items),
    }));
  }, [requestData.vat_percent]);
  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Putaway Request Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      handleGeneratePDF={props.handleGeneratePDF}
    >
      <GridDX
        container
        columnSpacing={1}
        rowSpacing={2}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 13px 1px #00000008",
          ml: "10px",
          px: 2,
          pt: 4,
          pb: 3,
        }}
        item
      >
        <GridDX item xs={12} md={4}>
          <UnitsDatePicker
            disabled
            label={t("Request Date")}
            name="request_date"
            value={requestData.request_date}
            onChange={(value: any) => handleDateChange(value, "request_date")}
            error={errors["request_date"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled
            label={t("Requested By")}
            name="requested_by_user_id"
            value={requestData.requested_by_user_id}
            onChange={handleInputChange}
            error={errors["requested_by_user_id"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            ref={fromWareHouseRef}
            label={t("From Warehouse")}
            name="from_warehouse_id"
            value={requestData.from_warehouse_id}
            onChange={handleInputChange}
            error={errors["from_warehouse_id"]}
            items={[
              { text: "Warehouse 1", value: "Warehouse 1" },
              { text: "Warehouse 2", value: "Warehouse 2" },
              { text: "Warehouse 3", value: "Warehouse 3" },
            ]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            ref={toWareHouseRef}
            label={t("To Warehouse")}
            name="to_warehouse_id"
            value={requestData.to_warehouse_id}
            onChange={handleInputChange}
            error={errors["to_warehouse_id"]}
            items={[
              { text: "Warehouse 1", value: "Warehouse 1" },
              { text: "Warehouse 2", value: "Warehouse 2" },
              { text: "Warehouse 3", value: "Warehouse 3" },
            ]}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <SelectListDX
            disabled
            label={t("Current Status")}
            name="current_status"
            value={requestData.current_status}
            onChange={handleInputChange}
            error={errors["current_status"]}
            items={[
              { text: "Pending", value: "Pending" },
              { text: "Approved", value: "Approved" },
              { text: "Rejected", value: "Rejected" },
            ]}
          />
        </GridDX>

        <GridDX item xs={12} md={12}>
          <TextFieldDX
            label={t("Receipt Notes")}
            name="receipt_notes"
            value={requestData.receipt_notes}
            onChange={handleInputChange}
            error={errors["receipt_notes"]}
            multiline
            minRows={2}
            maxRows={4}
          />
        </GridDX>

        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled
            label={t("Gross Amount")}
            name="gross_amount"
            value={requestData.gross_amount}
            onChange={handleInputChange}
            error={errors["gross_amount"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("VAT Percent")}
            name="vat_percent"
            value={requestData.vat_percent}
            onChange={handleInputChange}
            error={errors["vat_percent"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            disabled
            label={t("VAT Amount")}
            name="vat_amount"
            value={requestData.vat_amount}
            onChange={handleInputChange}
            error={errors["vat_amount"]}
          />
        </GridDX>

        <GridDX item xs={8}></GridDX>
        <GridDX item xs={4}>
          <BoxDX
            sx={{
              display: "flex",
              alignItems: "end",
              borderBottom: "2px solid",
              gap: 2,
            }}
          >
            <TypographyDX sx={{ fontSize: "18px" }}>Total Amount:</TypographyDX>
            <TypographyDX sx={{ fontSize: "18px", fontWeight: "bold" }}>
              {requestData.net_amount}
            </TypographyDX>
          </BoxDX>
        </GridDX>
      </GridDX>

      <GridDX
        container
        columnSpacing={1}
        rowSpacing={2}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 13px 1px #00000008",
          ml: "10px",
          p: 2,
          mt: 4,
        }}
        item
      >
        <GridDX item xs={12}>
          {requestData.items && (
            <PutawayRequestItems
              requestData={requestData}
              setRequestData={setRequestData}
              getData={getData}
              setIsChanged={setIsChanged}
            />
          )}
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default PutawayRequestForm;
