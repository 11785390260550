import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AddEditModalDX from "../../business/addeditmodaldx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import { useNotificationContext } from "../../../context/notificationcontext";
import { getTotalAmount, numberWithCommas } from "../../../shared/globals";
import TypographyDX from "../../layout/typographydx";
import BoxDX from "../../layout/boxdx";
import SelectListDX from "../../controls/selectlistdx";

const QuotationItemForm = (props: any) => {
  const {
    quotationItemId,
    quotationData,
    setQuotationData,
    handleClose,
    subscriptionPackages,
  } = props;
  const { t } = useTranslation();
  const defaultValues = {
    item_id: quotationData.quotationItems.length + 1 * 1000,
    itemName: "",
    description: "",
    unitPrice: 0,
    quantity: 1,
    discountPercent: 0,
    discountAmount: 0,
    totalAmount: 0,
    subscriptionPackageId: 0,
  };
  const [quotationItemData, setQuotationItemData] =
    useState<any>(defaultValues);
  const [originalData, setOriginalData] = useState<any>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { setInfo } = useNotificationContext();
  const isInitialMount = useRef(true);
  useEffect(() => {
    getData();
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (!quotationItemData.itemName)
      newErrors.subscriptionPackageId = t("Package is required");
    if (!quotationItemData.description)
      newErrors.description = t("Description is required");
    if (quotationItemData.quantity <= 0)
      newErrors.quantity = t("Quantity should be greater than 0");
    if (quotationItemData.unitPrice <= 0)
      newErrors.unitPrice = t("Unit Price should be greater than 0");
    if (quotationItemData.discountPercent < 0)
      newErrors.discountPercent = t(
        "Discount Percent should be greater than 0"
      );

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getData = async () => {
    if (quotationItemId) {
      setQuotationItemData((prev: any) => {
        return {
          ...prev,
          ...quotationData.quotationItems.find(
            (item: any) => item.item_id === quotationItemId
          ),
        };
      });
    }
  };
  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    let parsedValue = value;
    if (type === "number") {
      parsedValue = parseFloat(value); // Parse the value to a float
    }
    setQuotationItemData({
      ...quotationItemData,
      [name]: type === "checkbox" ? checked : parsedValue,
    });
  };
  useEffect(() => {
    setQuotationItemData((quotationItemData: any) => {
      const discountAmount =
        quotationItemData.unitPrice *
        quotationItemData.quantity *
        (quotationItemData.discountPercent / 100);
      const totalAmount =
        quotationItemData.unitPrice * quotationItemData.quantity -
        discountAmount;
      return {
        ...quotationItemData,
        totalAmount: totalAmount,
        discountAmount: discountAmount,
      };
    });
  }, [
    quotationItemData.quantity,
    quotationItemData.unitPrice,
    quotationItemData.discountPercent,
  ]);

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      const newItems = [...quotationData.quotationItems];
      const index = newItems.findIndex(
        (item: any) => item.item_id === quotationItemData.item_id
      );
      if (index > -1) {
        newItems[index] = quotationItemData;
        setInfo("Item updated successfully");
      } else {
        newItems.push({
          ...quotationItemData,
          item_id: newItems.length + 1,
        });
        setInfo("Item added successfully");
      }
      const updatedData = {
        ...quotationData,
        quotationItems: newItems,
      };
      setQuotationData({
        ...updatedData,
        ...getTotalAmount(updatedData, updatedData.quotationItems),
      });
      setIsChanged(false);
      setIsLoading(false);
      handleClose();
      props.setIsChanged(true);
    }
  };
  // useEffect(() => {
  //   console.log("initial mount", isInitialMount.current);
  //   if (isInitialMount.current) {
  //     isInitialMount.current = false;
  //   } else if (quotationItemData.subscriptionPackageId) {
  //     const selectedPackage = subscriptionPackages.find(
  //       (item: any) =>
  //         item.value.subscriptionPackageId ===
  //         quotationItemData.subscriptionPackageId
  //     );
  //     setQuotationItemData({
  //       ...quotationItemData,
  //       itemName: selectedPackage.value.name,
  //       description:selectedPackage.value.description,
  //       unitPrice: selectedPackage.value.price,
  //     });
  //   }
  // }, [quotationItemData.subscriptionPackageId]);
  return (
    <AddEditModalDX
      open={true}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={t("Quotation Item")}
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <Loading styles={{ height: "100%", width: "100%", left: 0 }} />
      )}

      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <SelectListDX
            label={t("Item")}
            name="subscriptionPackageId"
            items={subscriptionPackages.map((item: any) => ({
              text: item.text,
              value: item.value.subscriptionPackageId,
            }))}
            onChange={(e: any) => {
              const selectedPackage = subscriptionPackages.find(
                (item: any) =>
                  item.value.subscriptionPackageId === e.target.value
              );
              setQuotationItemData(() => ({
                ...quotationItemData,
                subscriptionPackageId: e.target.value,
                itemName: selectedPackage.value.name,
                description: selectedPackage.value.description,
                unitPrice: selectedPackage.value.price,
              }));
            }}
            error={errors["subscriptionPackageId"]}
            value={quotationItemData.subscriptionPackageId}
          />
        </GridDX>
        <GridDX item xs={12}>
          <TextFieldDX
            label={t("Description")}
            name="description"
            value={quotationItemData.description}
            onChange={handleInputChange}
            error={errors["description"]}
            multiline
            minRows={2}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            label={t("Unit Price")}
            name="unitPrice"
            value={quotationItemData.unitPrice}
            onChange={handleInputChange}
            error={errors["unitPrice"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            label={t("Quantity")}
            name="quantity"
            value={quotationItemData.quantity}
            onChange={handleInputChange}
            error={errors["quantity"]}
          />
        </GridDX>

        <GridDX item xs={12} md={6}>
          <TextFieldDX
            type="number"
            InputProps={{ inputProps: { min: 0, max: 50 } }}
            label={t("Discount Percent")}
            name="discountPercent"
            value={quotationItemData.discountPercent}
            onChange={handleInputChange}
            error={errors["discountPercent"]}
          />
        </GridDX>
        <GridDX item xs={12} md={6}>
          <TextFieldDX
            disabled
            label={t("Discount Amount")}
            name="discountAmount"
            value={numberWithCommas(quotationItemData.discountAmount)}
            onChange={handleInputChange}
            error={errors["discountAmount"]}
          />
        </GridDX>
        <GridDX item xs={6}></GridDX>
        <GridDX item xs={6} justifyContent="flex-end">
          <BoxDX
            sx={{
              display: "flex",
              alignItems: "end",
              borderBottom: "2px solid",
              gap: 2,
            }}
          >
            <TypographyDX sx={{ fontSize: "18px" }}>Total Amount:</TypographyDX>
            <TypographyDX sx={{ fontSize: "18px", fontWeight: "bold" }}>
              {numberWithCommas(quotationItemData.totalAmount.toFixed(2))}
            </TypographyDX>
          </BoxDX>
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default QuotationItemForm;
