import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import appLogo from "../../../assets/app_logo.png";
import LanguageSwitcher from "../../controls/languageswitcherdx";
import BoxDX from "../../layout/boxdx";
import ButtonDX from "../../controls/buttondx";
import {
  FormatQuoteOutlined,
  Logout,
  NotificationsNone,
  RequestQuoteOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import { useAuthContext } from "../../../context/authcontext";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TypographyDX from "../../layout/typographydx";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function UnitsAppBar(props: any) {
  const { forQuote = false } = props;
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  const location = useLocation();

  return (
    // <BoxDX sx={{ flexGrow: 1 }}>
    //   <AppBar
    //     position="fixed"
    //     sx={{
    //       backgroundColor: "#fff",
    //       boxShadow: "0 0 5px 0 rgba(0,0,0,0.2)",
    //       zIndex: (theme) => theme.zIndex.drawer + 1,
    //     }}
    //   >
    <Container
      sx={{
        position: "fixed",
        width: "-webkit-fill-available",
        maxWidth: "-webkit-fill-available !important",
        ml: "2px",
        marginInlineEnd: "24px",
        backgroundColor: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar disableGutters>
        {forQuote && (
          <img
            src={appLogo}
            style={{
              width: "100%", // Adjusted to fit the container width
              maxWidth: "200px", // Set max width for large screens
              maxHeight: "80px", // Set max height for large screens
              objectFit: "contain",
            }}
            alt="UNITS"
          />
        )}
        {/* <img src={appLogo} height={75} alt="Units" /> */}
        {/* {!forQuote && (
          <IconButton size="large">
            <SearchOutlined />
          </IconButton>
        )} */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
        <Box sx={{ flexGrow: 0 }}>
          <LanguageSwitcher color={"#6800F4"} mode={2} />
        </Box>
        {/* {location.pathname !== "/requestaquote" && (
              <Box sx={{ flexGrow: 0, mx: 2 }}>
                <ButtonDX
                  variant="contained"
                  color="primary"
                  size="small"
                  endIcon={<RequestQuoteOutlined />}
                  sx={{ py: 1, px: 2 }}
                  onClick={() => {
                    navigate("/requestaquote");
                  }}
                >
                  {t("Request a Quote")}
                </ButtonDX>
              </Box>
            )} */}
        {!forQuote && (
          <IconButton size="large">
            <NotificationsNone />
          </IconButton>
        )}
        {!forQuote && <AccountMenu />}
      </Toolbar>
    </Container>
    //   </AppBar>
    // </BoxDX>
  );
}
export default UnitsAppBar;

const AccountMenu: React.FC = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut, userData } = useAuthContext();

  const [openProfile, setOpenProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    handleClose();
    signOut();
    navigate("/");
  };

  const onProfileClick = () => {
    setOpenProfile(!openProfile);
    handleClose();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("Account settings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={props.open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={props.open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
        <TypographyDX
          sx={{ fontSize: "11px", color: "#656E7A", fontWeight: 500 }}
        >
          {userData?.fullName || "User"}
        </TypographyDX>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemIcon>
            <Avatar sx={{ width: 28, height: 28 }} />
          </ListItemIcon>
          {t("Profile")}
        </MenuItem>
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon sx={{ paddingLeft: 0.5 }}>
            <Logout fontSize="small" sx={{ width: 27, height: 25 }} />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
      {/* {openProfile && (
        <ProfileModal
          open={openProfile}
          onClose={(reload: any) => {
            setOpenProfile(false);
            reload && window.location.reload();
          }}
        />
      )} */}
    </React.Fragment>
  );
};
