import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";

const ChipDX = (props: any) => {
  const { i18n } = useTranslation();

  return (
    <Chip
      color={props.color}
      label={props.label}
      onDelete={() => props.onDelete()}
      sx={
        i18n.dir() === "rtl"
          ? {
              ...props.sx,
              "& .MuiChip-deleteIcon": {
                margin: "0 -6px 0 5px",
              },
            }
          : { ...props.sx }
      }
    />
  );
};

export default ChipDX;
