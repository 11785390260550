import { useEffect, useState } from "react";
import { t } from "i18next";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import { useNotificationContext } from "../../../context/notificationcontext";
import { Fab, InputAdornment } from "@mui/material";
import { Delete } from "@mui/icons-material";
import ButtonDX from "../../controls/buttondx";
import TypographyDX from "../../layout/typographydx";
import AddEditModalDX from "../../business/addeditmodaldx";
import useWarehouseRackLocationService from "../../../shared/services/warehouseracklocationservice";
import { useAuthContext } from "../../../context/authcontext";
import useOutboundRequestService from "../../../shared/services/outboundrequestservice";

const ItemPickingForm = (props: any) => {
  const { data, warehouseId, customerId, handleClose, open } = props;
  const { setError, setInfo } = useNotificationContext();
  const { getWarehouseRackLocationsByWarehouseId } =
    useWarehouseRackLocationService();
  const { pickSku } = useOutboundRequestService();

  const [locationList, setLocationList] = useState<any>([]);
  const [locations, setlocations] = useState<any>([
    { locationId: null, numberOfUnits: 0 },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const onSave = async () => {
    if (checkSum() > data.quantity) {
      setError("Quantity entered is greater than total");
    } else if (validateForm()) {
      const outboundRequestSkus = locations.map((item: any) => {
        return {
          outboundRequestSkuId: data.outboundRequestSkuId,
          warehouseId: warehouseId,
          locationId: item.locationId,
          skuId: data.skuId,
          quantity: item.numberOfUnits,

        };
      });
      const saveData = {
        outboundRequestId: data.outboundRequestId,
        customerId: customerId,
        outboundRequestSkus: outboundRequestSkus,
      };
      setIsLoading(true);
      pickSku(saveData)
        .then((res) => {
          setInfo(t("Sku picked successfully"));
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const checkSum = () => {
    let total = 0;
    for (let index in locations) {
      total += parseInt(locations[index].numberOfUnits);
    }
    return total;
  };

  const validateForm = () => {
    const newErrors: any = {};

    for (let item in locations) {
      if (!locations[item].locationId)
        newErrors[`locationId ${item}`] = t("This field is required");
      if (locations[item].numberOfUnits === 0)
        newErrors[`numberOfUnits ${item}`] = t("This field is required");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleLocationChange = (event: any, index: number) => {
    setlocations([
      ...locations.slice(0, index),
      { ...locations[index], locationId: event.target.value },
      ...locations.slice(index + 1),
    ]);
  };

  const handleValueChange = (event: any, index: number, newValue: number) => {
    setlocations([
      ...locations.slice(0, index),
      { ...locations[index], numberOfUnits: newValue },
      ...locations.slice(index + 1),
    ]);
  };

  const handleDelete = (index: number) => {
    if (locations.length > 1)
      setlocations([
        ...locations.slice(0, index),
        ...locations.slice(index + 1),
      ]);
  };

  useEffect(() => {
    getData();
  }, []);
  console.log({ props });

  const getData = () => {
    setIsLoading(true);
    getWarehouseRackLocationsByWarehouseId(warehouseId)
      .then((res) => {
        const arr = res.map((location: any) => {
          return {
            text: `${location.name} > ${location.rackName}`,
            value: location.warehouseRackLocationId,
          };
        });
        setLocationList(arr);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <AddEditModalDX
      open={open}
      title={t("Pick Item")}
      handleClose={handleClose}
      maxWidth="md"
      isSaving={isSaving}
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      <GridDX container rowSpacing={2} columnSpacing={1}>
        <GridDX item xs={12}>
          <TypographyDX fontWeight={"bold"}>
            {`${t("Selected SKU")}: `}
          </TypographyDX>
          <TypographyDX mx={1}>{data.skuName}</TypographyDX>
        </GridDX>
        {/* <GridDX item xs={12} mb={1}>
          <TypographyDX fontWeight={"bold"}>{`${t("Code")}: `}</TypographyDX>
          <TypographyDX mx={1}>{data.skuCode}</TypographyDX>
        </GridDX> */}
        {/* <GridDX item xs={12} mb={1}>
          <TypographyDX fontWeight={"bold"}>{t("Usable Units: ")}</TypographyDX>
          <TypographyDX mx={1}>
           {data.usableUnits - data.putAwayUnits} </TypographyDX>
        </GridDX> */}
        {locations.map((location: any, index: number) => (
          <>
            <GridDX item xs={12} md={4}>
              <SelectListDX
                disabled={isLoading}
                label={t("Select Location")}
                value={location.locationId}
                onChange={(event: any) => handleLocationChange(event, index)}
                items={locationList}
                error={errors[`locationId ${index}`]}
              />
            </GridDX>
            <GridDX item xs={12} md={4}>
              <TextFieldDX
                label={t("No. of Units")}
                name="numberOfUnits"
                value={location.numberOfUnits}
                onChange={(event: any) => {
                  let newValue = parseInt(event.target.value);
                  if (newValue > data.quantity - data.pickedQuantity){
                    newValue = data.quantity - data.pickedQuantity;
                  }
                  if (newValue > 100)
                    newValue = 100;
                  handleValueChange(event, index, newValue);
                }}
                type="number"
                error={errors[`numberOfUnits ${index}`]}
                // Disable spin buttons for number input
                className="noSpinButtons"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{`/${data.quantity - data.pickedQuantity || 0}`}</InputAdornment>
                  ),
                }}
              />
            </GridDX>
            <GridDX item xs={1} alignItems="center">
              <Fab
                size="small"
                disabled={locations.length === 1}
                onClick={() => handleDelete(index)}
              >
                <Delete />
              </Fab>
            </GridDX>
          </>
        ))}
        <GridDX item xs={12}>
          <ButtonDX
            variant="outlined"
            onClick={() =>
              setlocations([
                ...locations,
                { locationId: null, numberOfUnits: 0 },
              ])
            }
          >
            {t("Add")}
          </ButtonDX>
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default ItemPickingForm;
