import { t } from "i18next";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../context/notificationcontext";
import useCityService from "../../../shared/services/cityservice";
import useSupplierService from "../../../shared/services/supplierservice";
import SelectListDX from "../../controls/selectlistdx";
import TextFieldDX from "../../controls/textfielddx";
import GridDX from "../../layout/griddx";
import Loading from "../../layout/loading";
import AddEditEmbeddedModal from "../../units_controls/addeditembeddedmodal";

const SupplierForm = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const packageData = state?.packageData;
  const handleClose = () => {
    navigate(-1);
  };
  const { setInfo, setError } = useNotificationContext();
  const { addSupplier, updateSupplier } = useSupplierService();
  const { getCities } = useCityService();

  const defaultData = {
    code: "",
    name: "",
    phone: "",
    email: "",
    pocName: "",
    streetAddress: "",
    cityId: "",
  };

  const [data, setData] = useState<any>(defaultData);
  const [cities, setCities] = useState<any>([]);
  const [isChanged, setIsChanged] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsChanged(true);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = async () => {
    setIsLoading(true);
    getCities()
      .then((res) =>
        setCities(
          res.map((item: any) => {
            return {
              text: item.cityName,
              value: item.cityId,
            };
          })
        )
      )
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  const validateForm = () => {
    const newErrors: any = {};

    if (!data.name) newErrors["name"] = "Name is required";
    if (!data.code) newErrors["code"] = "Code is required";
    if (!data.phone) newErrors["phone"] = "Phone is required";
    if (!data.pocName) newErrors["pocName"] = "POC Name is required";
    if (!data.streetAddress) newErrors["streetAddress"] = "Street Address is required";
    if (!data.cityId) newErrors["cityId"] = "City is required";
    
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      setIsSaving(true);

      const operation = packageData
        ? updateSupplier(data.SupplierId, data)
        : addSupplier(data);
      operation
        .then(() => {
          setInfo(
            packageData
              ? t("Supplier updated successfully")
              : t("Supplier added successfully")
          );
          handleClose();
        })
        .catch((err) => setError(err))
        .finally(() => setIsSaving(false));
    }
  };

  useEffect(() => {
    if (packageData) setData(packageData);
    getData();
  }, []);

  return (
    <AddEditEmbeddedModal
      handleClose={handleClose}
      isSaving={isSaving}
      isChanged={isChanged}
      title={t("Supplier Details")}
      onSaveClick={onSave}
      onYesClick={handleClose}
      isPrintable={false}
    >
      <GridDX
        container
        sx={{
          p: 2,
        }}
        columnSpacing={1}
        rowSpacing={2}
      >
        {isLoading && <Loading sx={{ width: "100%", height: "100%" }} />}
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Code")}
            name="code"
            value={data?.code}
            onChange={handleInputChange}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Name")}
            name="name"
            value={data?.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>
        <GridDX item xs={12} md={4}>
          <TextFieldDX
            label={t("Email")}
            name="email"
            value={data?.email}
            onChange={handleInputChange}
            error={errors["email"]}
          />
        </GridDX>
        <GridDX item xs={12}>
          <GridDX item xs={12} md={4} sx={{ pr: 0.5 }}>
            <TextFieldDX
              label={t("POC Name")}
              name="pocName"
              value={data?.pocName}
              onChange={handleInputChange}
              error={errors["pocName"]}
            />
          </GridDX>
          <GridDX item xs={12} md={4} sx={{ pl: 0.5 }}>
            <TextFieldDX
              label={t("Phone Number")}
              name="phone"
              value={data?.phone}
              onChange={handleInputChange}
              error={errors["phone"]}
            />
          </GridDX>
        </GridDX>
        <GridDX item xs={12} md={4}>
          <SelectListDX
            label={t("City")}
            items={cities}
            name="cityId"
            value={data.cityId}
            onChange={handleInputChange}
            error={errors["cityId"]}
          />
        </GridDX>
        <GridDX item xs={12} md={8}>
          <TextFieldDX
            label={t("Street Address")}
            name="streetAddress"
            value={data?.streetAddress}
            onChange={handleInputChange}
            error={errors["streetAddress"]}
          />
        </GridDX>
      </GridDX>
    </AddEditEmbeddedModal>
  );
};

export default SupplierForm;
