import useSecureAPI from "../hooks/usesecureapi";

const useSupplierService = () => {
  const secureAPI = useSecureAPI();

  const getSuppliers = async () => {
    const response = await secureAPI.get("Supplier");
    return response.data;
  };

  const getSupplierById = async (id: number) => {
    const response = await secureAPI.get(`Supplier/${id}`);
    return response.data;
  };

  const addSupplier = async (data: any) => {
    const response = await secureAPI.post("Supplier", data);
    return response.data;
  };

  const addSuppliersInBulk = async (data: any) => {
    const response = await secureAPI.post("Supplier/bulk", data);
    return response.data;
  };

  const updateSupplier = async (id: number, data: any) => {
    const response = await secureAPI.put(`Supplier`, data);
    return response.data;
  };

  const archiveSupplier = async (id: number) => {
    const response = await secureAPI.put(`Supplier/archive/${id}`);
    return response.data;
  };

  return {
    getSuppliers,
    getSupplierById,
    addSupplier,
    addSuppliersInBulk,
    updateSupplier,
    archiveSupplier,
  };
};

export default useSupplierService;
