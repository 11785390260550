import { useEffect, useState } from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  Table,
  Typography,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { usePDF } from "react-to-pdf";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  downloadPDF,
  formattedNumber,
  numberWithCommas,
  DATE_FORMAT,
} from "../../../../shared/globals";
import TypeTranslator from "../../../../shared/typetranslator";
import ButtonDX from "../../../controls/buttondx";
import LoadingButtonDX from "../../../controls/loadingbuttondx";
import BoxDX from "../../../layout/boxdx";
import TypographyDX from "../../../layout/typographydx";
import customerLogo from "../../../../assets/customer_logo.png";
import useCustomerService from "../../../../shared/services/customerservice";
import useWarehouseService from "../../../../shared/services/warehouseservice";
import useCustomerAddressService from "../../../../shared/services/customeraddressservice";
import { useNotificationContext } from "../../../../context/notificationcontext";

const OutboundReport = (props: any) => {
  const { requestData, open, onClose } = props;
  const { setError } = useNotificationContext();
  const { getCustomerById } = useCustomerService();
  const { getWarehouseById } = useWarehouseService();
  const { getCustomerAddressById } = useCustomerAddressService();
  const { getPackagingTypeValue, getQuotationStatusValue } = TypeTranslator();
  const [customerData, setCustomerData] = useState<any>();
  const [warehouseData, setWarehouseData] = useState<any>();
  const [addressData, setAddressData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const [isPdfGenerating, setIsPdfGenerating] = useState(false);
  const { t } = useTranslation();
  const { toPDF, targetRef } = usePDF({
    filename: t(`Outbound Report - ${requestData?.outboundRequestId}`), // Set the file name
    page: {
      margin: 10, // Set the margin in millimeters
      format: "a4", // You can choose 'a4' or other formats
      orientation: "portrait", // 'portrait' or 'landscape'
    },
  });

  const getData = async () => {
    console.log("requestData", requestData);
    const p1 = getCustomerById(requestData.customerId);
    const p3 = getWarehouseById(requestData.warehouseId);
    const p4 = getCustomerAddressById(requestData.dropoffAddressId);

    setIsLoading(true);
    Promise.all([p1, p3, p4])
      .then(([customer, warehouse, address]) => {
        setCustomerData(customer);
        setWarehouseData(warehouse);
        setAddressData(address);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (requestData) getData();
  }, []);

  const handlePrintInvoice = async () => {
    downloadPDF({ toPDF, setIsPdfGenerating });
  };

  const TableHeadRow = () => (
    <TableRow className="table-header">
      <TableCell
        sx={{
          border: 1,
          textAlign: "center",
        }}
      >
        {t("SKU")}
      </TableCell>
      <TableCell
        sx={{
          border: 1,
          textAlign: "center",
        }}
      >
        {t("Quantity")}
      </TableCell>
      <TableCell
        sx={{
          border: 1,
          textAlign: "center",
        }}
      >
        {t("Packaging Type")}
      </TableCell>
    </TableRow>
  );
  const MainTableBody = ({ tableData }: any) =>
    tableData?.map((data: any, index: number) => (
      <TableRow key={index}>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center" }}>
            {data.sku}
            {/* {skus.find((s: any) => s.skuId === data?.skuId)?.name || ""} */}
          </Typography>
        </TableCell>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center" }}>
            {formattedNumber(data?.quantity)}
          </Typography>
        </TableCell>
        <TableCell sx={{ border: 1 }}>
          <Typography sx={{ textAlign: "center" }}>
            {data.packagingType}
            {/* {getPackagingTypeValue(data.packagingTypeId)} */}
          </Typography>
        </TableCell>
      </TableRow>
    ));

  const splitTableDataIntoPages = (data = [], maxRowsPerPage: number) => {
    let maxPerPage = maxRowsPerPage;
    const pages = [];
    for (let i = 0; i < data.length; i += maxPerPage) {
      if (pages.length > 0) {
        maxPerPage = 37;
        pages.push(data.slice(i, i + maxPerPage));
      } else {
        pages.push(data.slice(i, i + maxPerPage));
      }
    }
    return pages;
  };
  const dummyData = Array(10)
    .fill(null)
    .map((_, index) => ({
      sku: `SKU ${index}`,
      quantity: 100,
      packagingType: "Box",
    }));

  const tablePages = splitTableDataIntoPages(
    //@ts-ignore
    // requestData?.outboundRequestSkus,
    dummyData,
    25
  );

  useEffect(() => {
    if (targetRef.current) {
      // functionality for adding pagebreak to the printed pdf
      // const contentHeight = targetRef.current.offsetHeight;
      const pageHeight = 1480;
      let currentHeight = 0;

      const sections = targetRef.current.querySelectorAll(".section");

      sections.forEach((section: any) => {
        const sectionHeight = section.getBoundingClientRect().height;
        // check if the section is a table section
        if (section.classList.contains("tablesection")) {
          console.log("table section");
          currentHeight += sectionHeight;
          return;
        }
        if (currentHeight > pageHeight) {
          // only add margin if the section is not a visitor section
          isPdfGenerating
            ? (section.style.margin = `200px 0px 0px 0px`)
            : (section.style.margin = "0px 0px 0px 0px");
          currentHeight = sectionHeight;
        }
      });
    }
  }, [isPdfGenerating, targetRef]);

  return (
    <Dialog
      open={open}
      onClose={!isPdfGenerating && onClose}
      maxWidth={props.maxWidth ?? "xl"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ textAlign: "center" }}>Outbound Report</DialogTitle>
      <DialogActions
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <ButtonDX
          disabled={isPdfGenerating}
          variant="outlined"
          onClick={onClose}
          sx={{ mb: { xs: 2, sm: "auto" } }}
        >
          {props.cancelLabel ?? t("Cancel")}
        </ButtonDX>
        <LoadingButtonDX
          color="primary"
          onClick={handlePrintInvoice}
          loading={isPdfGenerating}
          sx={{ maxWidth: 120 }}
        >
          {t("Print")}
        </LoadingButtonDX>
      </DialogActions>
      <DialogContent>
        <div ref={targetRef} style={{ width: "1024px", padding: "10px" }}>
          <BoxDX className="section" sx={{ position: "relative" }}>
          <TypographyDX
              variant="h4"
              fontWeight="bold"
              // fontSize={"100px"}
              sx={{}}
            >
              {t("Outbound Request")}
            </TypographyDX>
            <TypographyDX variant="body1" sx={{ fontFamily: "Almarai" }}>
              مؤسسة جودة الودائع للنقل والتخزين
            </TypographyDX>
            <BoxDX sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                width={180}
                style={{ objectFit: "cover" }}
                src={customerLogo}
                alt="Customer Logo"
              />
            </BoxDX>
          </BoxDX>
          <Divider sx={{ borderBottomWidth: 2, mt: 2 }} />

          <BoxDX
            className="section"
            sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
          >
            <BoxDX sx={{  display: "flex" }}>
              <BoxDX>
                <TypographyDX variant="h6">Shipping Address</TypographyDX>
                <TypographyDX variant="body1" sx={{ fontFamily: "Almarai" }}>
                  Axx 123, Riyadh, Saudi Arabia
                </TypographyDX>
                <TypographyDX variant="body1">
                  <span style={{ fontWeight: "bold", marginRight: 2 }}>
                    Name:
                  </span>{" "}
                  {requestData.pocName}
                </TypographyDX>
                <TypographyDX variant="body1">
                  <span style={{ fontWeight: "bold", marginRight: 2 }}>
                    Phone:
                  </span>{" "}
                  {requestData.pocPhone}
                </TypographyDX>
              </BoxDX>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ mx: 5, borderWidth: 1 }}
              />
              <BoxDX>
                <TypographyDX variant="h6">Billing Address</TypographyDX>
                <TypographyDX variant="body1" sx={{ fontFamily: "Almarai" }}>
                  Axx 123, Riyadh, Saudi Arabia
                </TypographyDX>
                <TypographyDX variant="body1">
                  <span style={{ fontWeight: "bold", marginRight: 2 }}>
                    Name:
                  </span>{" "}
                  {requestData.pocName}
                </TypographyDX>
                <TypographyDX variant="body1">
                  <span style={{ fontWeight: "bold", marginRight: 2 }}>
                    Phone:
                  </span>{" "}
                  {requestData.pocPhone}
                </TypographyDX>
              </BoxDX>
            </BoxDX>

            <BoxDX>
              <BoxDX sx={{ display: "flex", gap: 1 }}>
                <TypographyDX
                  variant="p"
                  fontWeight="bold"
                  sx={{
                    justifyContent: "flex-end",
                    width: 150,
                  }}
                >
                  Status:
                </TypographyDX>
                <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                  {getQuotationStatusValue(requestData.status)}
                </TypographyDX>
              </BoxDX>
              <BoxDX sx={{ display: "flex", gap: 1 }}>
                <TypographyDX
                  variant="p"
                  fontWeight="bold"
                  sx={{ justifyContent: "flex-end", width: 150 }}
                >
                  Delivery Type:
                </TypographyDX>
                <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                  {requestData.outboundDeliveryType}
                </TypographyDX>
              </BoxDX>
              <BoxDX sx={{ display: "flex", gap: 1 }}>
                <TypographyDX
                  variant="p"
                  fontWeight="bold"
                  sx={{ justifyContent: "flex-end", width: 150 }}
                >
                  Request Date:
                </TypographyDX>
                <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                  {moment(requestData.requestDate).format(DATE_FORMAT)}
                </TypographyDX>
              </BoxDX>
              <BoxDX sx={{ display: "flex", gap: 1 }}>
                <TypographyDX
                  variant="p"
                  fontWeight="bold"
                  sx={{ justifyContent: "flex-end", width: 150 }}
                >
                  Schedule Date:
                </TypographyDX>
                <TypographyDX variant="p" sx={{ textAlign: "left" }}>
                  {moment(requestData.scheduleDate).format(DATE_FORMAT)}
                </TypographyDX>
              </BoxDX>
              <BoxDX sx={{ display: "flex", gap: 1 }}>
                <TypographyDX
                  variant="p"
                  fontWeight="bold"
                  sx={{
                    justifyContent: "flex-end",
                    width: 150,
                    backgroundColor: "#dddddd",
                  }}
                >
                  Amount:
                </TypographyDX>
                <TypographyDX
                  variant="p"
                  sx={{
                    textAlign: "left",
                    backgroundColor: "#dddddd",
                  }}
                >
                  {numberWithCommas(1000)} SAR
                </TypographyDX>
              </BoxDX>
            </BoxDX>
          </BoxDX>

          {/* table */}
          <Box
            className="section"
            sx={{
              width: "100%",
              mt: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Items Details
            </Typography>
          </Box>

          {isPdfGenerating ? (
            tablePages.map((tableData, pageIndex) => {
              return (
                <Table
                  className="section tablesection"
                  id={pageIndex.toString()}
                  key={pageIndex}
                  sx={{
                    minWidth: 650,
                    mt: pageIndex === 0 ? 2 : pageIndex === 1 ? 20 : 10,
                    "& .MuiTableCell-root": {
                      py: 0.8,
                    },
                  }}
                >
                  <TableBody>
                    <TableHeadRow />
                    <MainTableBody tableData={tableData} />
                  </TableBody>
                </Table>
              );
            })
          ) : (
            <Table
              sx={{
                minWidth: 650,
                mt: 2,
                "& .MuiTableCell-root": {
                  py: 0.8,
                },
              }}
            >
              <TableBody>
                <TableHeadRow />
                {/* <MainTableBody tableData={requestData.outboundRequestSkus} /> */}
                <MainTableBody tableData={dummyData} />
              </TableBody>
            </Table>
          )}

          <BoxDX
            className="section"
            sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
          >
            <BoxDX></BoxDX>
            <BoxDX>
              <BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{
                      justifyContent: "flex-end",
                      width: 150,
                    }}
                  >
                    Shipping Cost:
                  </TypographyDX>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ textAlign: "left" }}
                  >
                    {numberWithCommas(1000)} SAR
                  </TypographyDX>
                </BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ justifyContent: "flex-end", width: 150 }}
                  >
                    Total VAT:
                  </TypographyDX>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ textAlign: "left" }}
                  >
                    {0}
                    SAR
                  </TypographyDX>
                </BoxDX>
                <BoxDX sx={{ display: "flex", gap: 1 }}>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ justifyContent: "flex-end", width: 150 }}
                  >
                    Total:
                  </TypographyDX>
                  <TypographyDX
                    variant="p"
                    fontWeight="bold"
                    sx={{ textAlign: "left" }}
                  >
                    {numberWithCommas(1000)} SAR
                  </TypographyDX>
                </BoxDX>
              </BoxDX>
            </BoxDX>
          </BoxDX>

          <Grid
            className="section"
            container
            sx={{ borderTop: "2px solid", pt: 2, mt: 2 }}
          >
            <Grid item sm={12} md={3}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Warehouse
              </Typography>
              <Typography> {requestData.warehouseName} </Typography>
            </Grid>
            <Grid item sm={12} md={3}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Cargo Type
              </Typography>
              <Typography> {requestData.cargoType} </Typography>
            </Grid>
            <Grid item sm={12} md={2}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Cargo Size
              </Typography>
              <Typography> {requestData.cargoSize} </Typography>
            </Grid>
            <Grid item sm={12} md={2}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Number of Cars
              </Typography>
              <Typography> {requestData.numberOfCars} </Typography>
            </Grid>
            <Grid item sm={12} md={2}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                Car Type
              </Typography>
              <Typography> {requestData.carType} </Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OutboundReport;
