import useSecureAPI from "../hooks/usesecureapi";

const useWarehouseAreaService = () => {
  const secureAPI = useSecureAPI();

  
  const addWarehouseArea = async (data: any) => {
    const response = await secureAPI.post("WarehouseArea", data);
    return response.data;
  };
  const addWarehouseAreasInBulk = async (data: any) => {
    const response = await secureAPI.post("WarehouseArea/bulk", data);
    return response.data;
  };
  const getWarehouseAreasByWarehouseId = async (id: number) => {
    const response = await secureAPI.get(`WarehouseArea/forwarehouse/${id}`);
    return response.data;
  }
  const getWarehouseAreaById = async (id: number) => {
    const response = await secureAPI.get(`WarehouseArea/${id}`);
    return response.data;
  }

  const updateWarehouseArea = async (data: any) => {
    const response = await secureAPI.put(`WarehouseArea`, data);
    return response.data;
  }
  const archiveWarehouseArea = async (id: number) => {
    const response = await secureAPI.put(`WarehouseArea/archive/${id}`);
    return response.data;
  }

  return {
    addWarehouseArea,
    addWarehouseAreasInBulk,
    getWarehouseAreasByWarehouseId,
    getWarehouseAreaById,
    updateWarehouseArea,
    archiveWarehouseArea
  };
};

export default useWarehouseAreaService;
