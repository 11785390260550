import { useTranslation } from "react-i18next";
import GridDX from "../../../layout/griddx";
import UtilizationBox from "../../../units_components/utilizationbox";
import GaugeBoxDX from "../../../layout/gaugeboxdx";
import SelectListDX from "../../../controls/selectlistdx";
import BoxDX from "../../../layout/boxdx";
import { useState } from "react";
import ProgressBarDX from "../../../layout/progressbardx";
import TypographyDX from "../../../layout/typographydx";

const Utilization = () => {
  const { t } = useTranslation();

  const [warehouseId, setWarehouseId] = useState(0);
  const warehouses = [
    { text: "Warehouse 1", value: 1 },
    { text: "Warehouse 2", value: 2 },
    { text: "Warehouse 3", value: 3 },
    { text: "Warehouse 4", value: 4 },
  ];

  return (
    <GridDX container columnSpacing={1} rowSpacing={2} sx={{ width: "100%" }}>
      <GridDX item xs={12} justifyContent="end" alignItems="center">
        <BoxDX width={15} height={15} backgroundColor={"#52C41A"} mx={1} />
        <TypographyDX fontSize={13}> {` 80% ${t("or more")},`} </TypographyDX>
        <BoxDX width={15} height={15} backgroundColor={"#FF8A36"} mx={1} />
        <TypographyDX fontSize={13}>
          {` ${t("between")} 50 - 80%,`}
        </TypographyDX>
        <BoxDX width={15} height={15} backgroundColor={"#F94839"} mx={1} />
        <TypographyDX fontSize={13}> {` 50% ${t("or less")}`} </TypographyDX>
      </GridDX>
      <GridDX item xs={12} justifyContent="end">
        <BoxDX mt={1} width={350}>
          <SelectListDX
            label={t("Warehouse")}
            name="warehouseId"
            value={warehouseId}
            onChange={(e: any) => setWarehouseId(e.target.value)}
            items={[...warehouses, { text: t("All Warehouses"), value: 0 }]}
          />
        </BoxDX>
      </GridDX>
      <GridDX
        item
        xs={12}
        alignItems="start"
        justifyContent="space-around"
        flexDirection="column"
      >
        {warehouseId === 0 && (
          <>
            <BoxDX
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="end"
              width={"100%"}
            >
              <TypographyDX fontSize={24} fontWeight="bold">
                {t("Overall Capacity:")}
              </TypographyDX>
              <TypographyDX fontSize={22} fontWeight="bold">
                {"65/80 CBM"}
              </TypographyDX>
            </BoxDX>
            <ProgressBarDX value={88} />
          </>
        )}
      </GridDX>
      {warehouseId === 0 ? (
        warehouses.map((warehouse) => (
          <GridDX item xs={3} maxHeight={350}>
            <GaugeBoxDX title={warehouse.text} value={67} />
          </GridDX>
        ))
      ) : (
        <>
          <GridDX item xs={9} minHeight={350}>
            <UtilizationBox />
          </GridDX>
          <GridDX item xs={3} maxHeight={350}>
            <GaugeBoxDX title={t("Total Occupied")} value={67} />
          </GridDX>
        </>
      )}
    </GridDX>
  );
};

export default Utilization;
